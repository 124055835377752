import BaseDataProvider from "./BaseDataProvider"

export default abstract class AuthServerProvider extends BaseDataProvider {
    /**
     * Uses REACT_APP_AUTHSERVER_ENDPOINT as the base-URL.
     * 
     * @param path The path to add to the base-URL. 
     * @param bearerToken The Bearer-token of the current user.
     */
    constructor(path?: string, bearerToken?: string) {
        if (!process.env.REACT_APP_AUTHSERVER_ENDPOINT) throw new Error("REACT_APP_AUTHSERVER_ENDPOINT is missing.")
        
        super(path, bearerToken, process.env.REACT_APP_AUTHSERVER_ENDPOINT)
    }
}