import { FC } from "react"

interface IProps {
    tag: string
    onClick?: (tag: string) => void
    selected?: boolean
}

const Tag: FC<IProps> = ({ tag, onClick, selected = false }) => {
    return (
        <div
            className={`tag ${selected && "tag--selected"}`}
            onClick={onClick ? () => onClick(tag) : undefined}
        >
            <p>{tag}</p>
        </div>
    )
}

export default Tag