import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { BadRequest } from "../types/BadRequest";

export class ExceptionHelper {
    static isBadRequest(error: FetchBaseQueryError | SerializedError | undefined) {
        const e = error as BadRequest

        if (e.status === 400) {
            return true
        }
    }
}