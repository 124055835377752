import { BadRequest } from '../../../types/BadRequest'
import { api } from '../../api/ApiSlice'
import { TimePeriodMutation } from './TimePeriodMutation'
import { ExpertRequest } from '../ExpertRequest'
import { TimePeriod } from './TimePeriod'
import { Appointment } from './Appointment'

const timePeriodsApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getAllTimePeriods: builder.query<TimePeriod[], { expertId: string, startDate: string, endDate: string }>({
            query: ({ expertId, startDate, endDate }) =>
                `/experts/${expertId}/time-periods?startDate=${startDate}&endDate=${endDate}&offset=${-(new Date().getTimezoneOffset() / 60)}`,
            providesTags: [{ type: 'TimePeriod', id: 'LIST' }],
        }),
        createTimePeriod: builder.mutation<TimePeriod, ExpertRequest<TimePeriodMutation>>({
            query: ({ expertId, data: timePeriod }) => ({
                url: `/experts/${expertId}/time-periods`,
                method: "POST",
                body: { ...timePeriod, maxPeople: parseInt(timePeriod.maxPeople.toString()) },
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            },
            invalidatesTags: [{ type: 'TimePeriod', id: 'LIST' }],
        }),
        deleteTimePeriod: builder.mutation<void, ExpertRequest<string>>({
            query: ({ expertId, data }) => ({
                url: `/experts/${expertId}/time-periods/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: 'TimePeriod', id: 'LIST' }]
        }),
        getTimePeriod: builder.query<TimePeriod, { expertId: string, timePeriodId: string }>({
            query: ({ expertId, timePeriodId }) => `/experts/${expertId}/time-periods/${timePeriodId}`
        }),
        getAppointmentsOnDate: builder.query<Appointment[], { expertId: string, timePeriodId: string, date: string }>({
            query: ({ expertId, timePeriodId, date }) => `/experts/${expertId}/time-periods/${timePeriodId}/appointments?date=${date}`
        })
    }),
})

export const {
    useCreateTimePeriodMutation,
    useGetAllTimePeriodsQuery,
    useDeleteTimePeriodMutation,
    useGetAppointmentsOnDateQuery,
    useGetTimePeriodQuery
} = timePeriodsApi