import { FC } from "react"
import { Logo } from "../Logo/Logo"

const AvatarPlaceholder: FC = () => {
    return (
        <div className="avatar-placeholder">
            <Logo.Small
                className="avatar-placeholder__logo"
            />
        </div>
    )
}

export default AvatarPlaceholder