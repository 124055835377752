import { FC, useState } from "react"

interface IProps {
    src: string,
    className?: string
}

const AsyncImage: FC<IProps> = ({ src, className }) => {
    const [isLoading, setIsLoading] = useState<boolean>(true)

    return (
        <>
            <div
                className={`image-skeleton__loader ${isLoading && "image-skeleton__loader--loading"}`}
            >
            </div>

            <img
                className={className}
                src={src}
                alt=""
                onLoad={() => setIsLoading(false)}
                onError={() => setIsLoading(false)}
            />
        </>
    )
}

export default AsyncImage