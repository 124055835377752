import { FC } from "react";
import Header from "../../shared/header/Header";
import Calendar from "./calendar/Calendar";
import { CalendarContextProvider } from "./context/CalendarContext";
import TimePeriodDetail from "./modals/time-period-detail/TimePeriodDetail";

const CalendarPage: FC = () => {
    return (
        <CalendarContextProvider>
            <div className="calendar-page page">
                <Header>
                    <h1>Agenda</h1>
                </Header>

                <Calendar />
            </div>

            <TimePeriodDetail />
        </CalendarContextProvider>
    )
}

export default CalendarPage