import { createSlice } from "@reduxjs/toolkit";
import { UserState } from "./UserState";
import { ErrorResponse, User, UserManager } from "oidc-client-ts";
import { ActionPayload, AppDispatch, AppThunk, RootState } from "../../types";
import { AuthState } from "./AuthState";
import { useSelector } from "react-redux";
import { useAppSelector } from "../../hooks";

const userManager = new UserManager({
    client_id: "fitbuddy-expert",
    authority: `${process.env.REACT_APP_AUTHSERVER_ENDPOINT}`,
    redirect_uri: `${process.env.REACT_APP_URL}/auth/redirect`,
    post_logout_redirect_uri: process.env.REACT_APP_URL,
    scope: "openid email profile name role offline_access account_info IdentityServerApi",
    loadUserInfo: true,
    automaticSilentRenew: false,
})

const initialState: UserState = {
    status: AuthState.notAuthenticated
}

export const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action: ActionPayload<User>) => {
            state.user = action.payload

            if (action.payload !== null) {
                state.status = AuthState.authenticated
            } else {
                state.status = AuthState.notAuthenticated
            }
        },
        setError: (state, action: ActionPayload<string | undefined>) => {
            state.status = AuthState.error
            state.error = action.payload
        },
        clearError: (state) => {
            state.error = undefined
        },
        clearUser: (state) => {
            state.user = undefined
        }
    }
})

const userActions = UserSlice.actions
export const useUser = () => useAppSelector(state => state.userSlice.user)
export const useUserId = () => useAppSelector(state => state.userSlice.user?.profile.sub ?? "")

const accessTokenExpiredCallback = (dispatch: AppDispatch) => () => {
    userManager.signinSilent()
        .then(user => {
            console.log("Signed in silently.")

            user === null ?
                dispatch(userActions.clearUser())
                :
                dispatch(userActions.setUser(user))
        })
        .catch(e => {
            if (e instanceof ErrorResponse && e.error === "invalid_grant") {
                dispatch(removeUserAsync())
                dispatch(signInRedirectCallbackAsync())
            }
        })
}

export const loadUserAsync = (): AppThunk => async (dispatch) => {
    await userManager.clearStaleState()

    const user = await userManager.getUser()

    if (user === null) {
        await userManager.signinRedirect()
    } else {
        userManager.events.removeAccessTokenExpired(accessTokenExpiredCallback(dispatch))
        userManager.events.addAccessTokenExpired(accessTokenExpiredCallback(dispatch))

        dispatch(userActions.setUser(user))
        dispatch(userActions.clearError())
    }
}

export const signOutAsync = (): AppThunk => async (dispatch) => {
    await userManager.signoutRedirect()

    dispatch(userActions.clearUser())
}

export const removeUserAsync = (): AppThunk => async (dispatch) => {
    await userManager.removeUser()

    dispatch(userActions.clearUser())
}

export const signInRedirectCallbackAsync = (): AppThunk => async (dispatch) => {
    try {
        const user = await userManager.signinRedirectCallback()

        dispatch(userActions.setUser(user))
    } catch (e) {
        if (e instanceof ErrorResponse && e.error === "invalid_grant") {
            dispatch(userActions.setError("Er ging iets fout bij het inloggen. Onze excuses voor het ongemak."))
        }

        if (e instanceof Error && e.message === "No matching state found in storage") {
            dispatch(userActions.setError("Er ging iets fout bij het inloggen. Onze excuses voor het ongemak."))
        }
    }
}

export const userReducer = UserSlice.reducer