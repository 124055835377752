import { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import MaterialIcon from "../../../../../components/Icons/MaterialIcon"
import ImageHelper from "../../../../../../utils/ImageHelper"
import Cropper, { Area } from "react-easy-crop"
import LoaderOverlay from "../../../../../components/LoaderOverlay/LoaderOverlay"
import Portal from "../../../../shared/portal/Portal"
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextButton from "../../../../../components/Buttons/TextButton"
import { useUploadGalleryMutation } from "../../../../../../redux/slices/experts/profile/ExpertProfileApiSlice"
import { useUserId } from "../../../../../../redux/slices/user/UserSlice"

const UploadContainer: FC = () => {
    const [uploadGallery] = useUploadGalleryMutation()

    const userId = useUserId()

    const [currentImage, setCurrentImage] = useState<string>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedArea, setCroppedArea] = useState<Area>()
    const [zoom, setZoom] = useState(1)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const formRef = useRef<HTMLFormElement>(null)
    const imageInputRef = useRef<HTMLInputElement>(null)

    const onClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click()
        }
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && formRef.current) {
            const image = e.target.files[0]

            const reader = new FileReader()
            reader.readAsDataURL(image)
            reader.addEventListener("load", () => {
                if (typeof reader.result === "string") {
                    setCurrentImage(reader.result)
                }
            }, { once: true })
        }
    }

    const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }

    const handleSubmit = async () => {
        if (currentImage == null || croppedArea == null) return

        setIsLoading(true)

        const imageCanvas = await ImageHelper.getCroppedImageCanvas(currentImage, croppedArea)

        imageCanvas.toBlob(blob => {
            if (blob === null) {
                setIsLoading(false)

                throw new Error()
            }

            const data = new FormData()

            data.append("data", blob, "profile-image.jpg")

            uploadGallery({
                expertId: userId,
                data
            })
                .unwrap()
                .then(() => {
                    setIsLoading(false)
                    setCurrentImage(undefined)
                })
        }, "image/jpeg")
    }

    useEffect(() => {
        if (currentImage == null && formRef.current) {
            formRef.current.reset()
        }
    }, [currentImage])

    return (
        <>
            {
                currentImage &&
                <Portal>
                    <div className="profile-extra__upload-container-modal">
                        <Cropper
                            crop={crop}
                            zoom={zoom}
                            image={currentImage}
                            aspect={16 / 10}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                        />
                        <div className="profile-extra__upload-container-buttons-container">
                            <div className="profile-extra__upload-container-buttons">
                                <FilledButton
                                    color="flame"
                                    onClick={handleSubmit}
                                >
                                    Opslaan
                                </FilledButton>

                                <TextButton
                                    color="white"
                                    onClick={() => setCurrentImage(undefined)}
                                >
                                    Annuleren
                                </TextButton>
                            </div>
                        </div>
                    </div>
                </Portal>
            }
            <div className="profile-extra__upload-container" onClick={onClick}>
                <LoaderOverlay show={isLoading} />

                <MaterialIcon>add</MaterialIcon>

                <form method="post" encType="multipart/form-data" ref={formRef}>
                    <div>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            hidden
                            ref={imageInputRef}
                        />
                    </div>
                </form>
            </div>
        </>
    )
}

export default UploadContainer