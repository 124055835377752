import { PaginatedSearchRequest } from '../../types/PaginatedRequest'
import { Paginated } from '../../types/Paginated'
import { api } from '../api/ApiSlice'
import { BadRequest } from '../../types/BadRequest'
import { Tag } from './Tag'

const tagApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getTags: builder.query<Paginated<Tag>, PaginatedSearchRequest>({
            query: ({ page, pageSize: size }) => `/tags?page=${page}&pageSize=${size}`,
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.items.map((item) => ({ type: 'Tag', item } as const)),
                        { type: 'Tag', id: 'LIST' },
                    ]
                    :
                    [{ type: 'Tag', id: 'LIST' }],
        }),
        getAllTags: builder.query<Tag[], void>({
            query: () => `/tags`,
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.map((item) => ({ type: 'Tag', item } as const)),
                        { type: 'Tag', id: 'LIST' },
                    ]
                    :
                    [{ type: 'Tag', id: 'LIST' }],
        }),
        createTag: builder.mutation<Tag, string>({
            query: (tag) => ({
                url: "/tags",
                method: "POST",
                body: { tag },
            }),
            transformErrorResponse: (error) => {
                console.log(error)
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            },
            invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
        }),
        deleteTag: builder.mutation<void, string>({
            query: (tag) => ({
                url: `/tags/${tag}`,
                method: "DELETE",
            }),
            invalidatesTags: [{ type: 'Tag', id: 'LIST' }],
        })
    }),
})

export const {
    useGetTagsQuery,
    useGetAllTagsQuery,
    useCreateTagMutation,
    useDeleteTagMutation
} = tagApi