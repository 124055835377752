import { FC, useState } from "react";
import MaterialIcon from "../../Icons/MaterialIcon";

interface IProps {
    options: { text: string, value: any }[],
    onChange: (value: any) => void,
    value?: any
}

const Select: FC<IProps> = ({ options, onChange, value }) => {
    const [expanded, setExpanded] = useState<boolean>()

    const handleChange = (value: any) => {
        onChange(value)
        setExpanded(false)
    }

    return (
        <div className="select">
            <div className="select__input-container"
                onClick={() => setExpanded(!expanded)}
            >
                <p>{value ? options.find(v => v.value === value)?.text : "Select..."}</p>

                {
                    expanded
                        ? <MaterialIcon>expand_less</MaterialIcon>
                        : <MaterialIcon>expand_more</MaterialIcon>
                }
            </div>

            <div
                className={`select__options-container ${expanded && `select__options-container--expanded`}`}
            >
                {
                    options.map(option => (
                        <div
                            key={`select-${option.text}`}
                            className="select__option"
                            onClick={() => handleChange(option.value)}
                        >
                            {option.text}
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default Select