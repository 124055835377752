import './App.scss';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ExpertsPage from './presentation/pages/admin/experts-page/ExpertsPage';
import Restricted from './presentation/pages/shared/restricted/Restricted';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import AuthRedirectPage from './presentation/pages/auth/auth-redirect-page/AuthRedirectPage';
import AppContainer from './presentation/pages/shared/layout/app-container/AppContainer';
import { AdminRoutes } from './routes/AdminRoutes';
import TagsPage from './presentation/pages/admin/tags-page/TagsPage';
import { ExpertRoutes } from './routes/ExpertRoutes';
import CustomersPage from './presentation/pages/expert/customers/CustomersPage';
import Contexts from './presentation/contexts/Contexts';
import ChatsPage from './presentation/pages/expert/chats/ChatsPage';
import ProfilePage from './presentation/pages/expert/profile/ProfilePage';
import ResetPasswordPage from './presentation/pages/auth/reset-password-page/ResetPasswordPage';
import CalendarPage from './presentation/pages/expert/calendar/CalendarPage';

const App = () => {
  return (
    <Provider store={store}>
      <Contexts>
        <HelmetProvider>
          <BrowserRouter>
            <Helmet>
              <title>Fit Buddy</title>
            </Helmet>

            <Routes>
              <Route
                path="auth/redirect"
                element={<AuthRedirectPage />}
              />

              <Route
                path="/account/reset-password"
                element={<ResetPasswordPage />}
              />

              <Route element={<AppContainer />}>
                {/* Admin routes */}
                <Route
                  path={AdminRoutes.experts.path}
                  element={
                    <Restricted roles={["admin"]}>
                      <ExpertsPage />
                    </Restricted>
                  }
                />

                <Route
                  path={AdminRoutes.tags.path}
                  element={
                    <Restricted roles={["admin"]}>
                      <TagsPage />
                    </Restricted>
                  }
                />

                {/* Expert routes */}
                <Route
                  path={ExpertRoutes.customers.path}
                  element={
                    <Restricted roles={["expert"]}>
                      <CustomersPage />
                    </Restricted>
                  }
                />

                <Route
                  path={ExpertRoutes.chats.path}
                  element={
                    <Restricted roles={["expert"]}>
                      <ChatsPage />
                    </Restricted>
                  }
                />

                <Route
                  path={ExpertRoutes.profile.path}
                  element={
                    <Restricted roles={["expert"]}>
                      <ProfilePage />
                    </Restricted>
                  }
                />

                <Route
                  path={ExpertRoutes.calendar.path}
                  element={
                    <Restricted roles={["expert"]}>
                      <CalendarPage />
                    </Restricted>
                  }
                />

                <Route
                  path="*"
                  element={
                    <Navigate to="profile" />
                  }
                />

              </Route>
            </Routes>
          </BrowserRouter>
          <div id="portal" />
        </HelmetProvider>
      </Contexts>
    </Provider>
  );
}

export default App;
