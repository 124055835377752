import { FC, useContext, useEffect, useState } from "react";
import Input from "../../../../../components/Inputs/Input/Input";
import TextButton from "../../../../../components/Buttons/TextButton";
import MaterialIcon from "../../../../../components/Icons/MaterialIcon";
import CalendarContext from "../../context/CalendarContext";
import TimeSelect from "../../../../../components/Inputs/TimeSelect/TimeSelect";
import Checkbox from "../../../../../components/Inputs/Checkbox/Checkbox";
import FilledButton from "../../../../../components/Buttons/FilledButton";
import { useCreateTimePeriodMutation, useDeleteTimePeriodMutation } from "../../../../../../redux/slices/experts/time-period/TimePeriodApiSlice";
import Loader from "../../../../../components/Loader/Loader";
import Modal from "../../../../shared/modals/Modal";
import DaySelector from "./day-selector/DaySelector";
import DatePicker from "../../../../../components/DatePicker/DatePicker";
import { useUserId } from "../../../../../../redux/slices/user/UserSlice";

interface IProps {
    positionX?: number
    positionY?: number,
    containerWidth?: number,
    containerMaxHeight?: number
}

const TimePeriodModal: FC<IProps> = ({ positionX, positionY, containerWidth, containerMaxHeight }) => {
    console.log(containerMaxHeight)
    const [showConfirm, setShowConfirm] = useState<boolean>(false)

    const userId = useUserId();

    const [
        createTimePeriod,
        {
            isLoading: isCreating,
            isError: isCreatingError,
            isSuccess: isCreatingSuccess,
            error: creationErrors
        }
    ] = useCreateTimePeriodMutation()

    const [
        deleteTimePeriod,
        {
            isSuccess: isDeletionSuccess,
        }
    ] = useDeleteTimePeriodMutation()

    const { emptyTimePeriod, originalTimePeriod, timePeriod, editTimePeriod, setDetailModal } = useContext(CalendarContext)

    useEffect(() => {
        if (isCreatingSuccess || isDeletionSuccess) emptyTimePeriod()
    }, [isCreatingSuccess, isDeletionSuccess, emptyTimePeriod])

    const isEditing = !!timePeriod?.id

    if (!positionX || !positionY || !containerWidth) return <></>

    const left = positionX + -408
    const leftOverflow = left < 0

    const handleChange = (field: string, value: any) => {
        editTimePeriod({ [field]: value })
    }

    const handleSubmit = () => {
        if (timePeriod) {
            if (!timePeriod?.id) {
                // Create
                createTimePeriod({
                    expertId: userId,
                    data: timePeriod
                })
            } else {
                // Update
            }
        }
    }

    const renderError = (field: string, label?: string) => {
        if (creationErrors && 'data' in creationErrors) {
            let data = creationErrors.data as Record<string, string>
            data = Object.entries(data).reduce((prev, [key, value]) => ({ ...prev, [key[0].toLowerCase() + key.substring(1)]: value }), {})
            let shortError = data[field]
            let fullError = "Er is een onbekende fout opgetreden."

            if (shortError === undefined) return <></>

            switch (shortError[0]) {
                case "Required": {
                    fullError = label + " is vereist."
                    break
                }
                case "Overlap": {
                    fullError = "Tijdvak overlapt met een ander tijdvak."
                    break
                }
                default: {

                }
            }

            return <p className="time-period-modal__error">{fullError}</p>
        }
        return <></>
    }

    return (
        <>
            <Modal
                show={showConfirm}
                onClose={() => setShowConfirm(false)}
            >
                <h1 className="time-period-modal__delete-title">Tijdvak verwijderen</h1>

                <p className="time-period-modal__delete-description">Weet je zeker dat je dit tijdvak wilt verwijderen?</p>

                <div className="time-period-modal__delete-buttons">
                    <FilledButton
                        color="flame"
                        className="time-period-modal__delete-button"
                        onClick={() => deleteTimePeriod({
                            expertId: userId,
                            data: timePeriod?.id!
                        })}
                    >
                        Verwijderen
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="time-period-modal__delete-button"
                        onClick={() => setShowConfirm(false)}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>

            <div
                className={`time-period-modal ${isCreatingError}`}
                style={{
                    left: !leftOverflow ? left + "px" : left + 416 + containerWidth,
                    top: positionY + 5,
                    maxHeight: containerMaxHeight
                }}
            >
                <div className={`time-period-modal__loader ${isCreating && "time-period-modal__loader--show"}`}>
                    <Loader />
                </div>

                <div className="time-period-modal__header">
                    {
                        isEditing &&
                        <>
                            <TextButton
                                color="grey"
                                iconOnly
                                withIcon
                                className="time-period-modal__detail-modal-button"
                                onClick={() => setDetailModal(true)}
                            >
                                <MaterialIcon>edit</MaterialIcon>
                            </TextButton>
                            <TextButton
                                color="grey"
                                iconOnly
                                withIcon
                                className="time-period-modal__delete-button"
                                onClick={() => setShowConfirm(true)}
                            >
                                <MaterialIcon>delete</MaterialIcon>
                            </TextButton>
                        </>
                    }

                    <TextButton
                        color="grey"
                        iconOnly
                        withIcon
                        className="time-period-modal__close-button"
                        onClick={emptyTimePeriod}
                    >
                        <MaterialIcon>close</MaterialIcon>
                    </TextButton>
                </div>

                <div className="time-period-modal__body">
                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Type</p>
                            <Input
                                placeholder="Type"
                                value={timePeriod?.type}
                                onChange={(event) => handleChange("type", event.currentTarget.value)}
                                underlined
                            />
                            {renderError("type", "Type")}
                        </div>
                    </div>

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Beschrijving</p>
                            <Input
                                placeholder="Beschrijving"
                                value={timePeriod?.description}
                                onChange={(event) => handleChange("description", event.currentTarget.value)}
                                underlined
                            />
                            {renderError("description", "Beschrijving")}
                        </div>
                    </div>

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Prijs</p>
                            <Input
                                placeholder="Prijs"
                                type="number"
                                step="0.01"
                                value={timePeriod?.price}
                                onChange={(event) => handleChange("price", event.currentTarget.value)}
                                underlined
                            />
                            {renderError("price", "Prijs")}
                        </div>
                    </div>

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Max. aantal mensen</p>

                            <Input
                                type="number"
                                placeholder="1"
                                value={timePeriod?.maxPeople.toString()}
                                onChange={(event) => handleChange("maxPeople", event.currentTarget.value)}
                            />

                            {renderError("customerOnly", "Max. aantal mensen")}
                        </div>
                    </div>

                    {
                        isEditing &&
                        <div className="time-period-modal__input-container">
                            <div className="time-period-modal__input-wrapper">
                                <p className="time-period-modal__label">Inschrijvingen</p>

                                <p className="time-period-modal__input-text">
                                    {originalTimePeriod?.registrationNumber} / {originalTimePeriod?.maxPeople}
                                </p>

                                <a
                                    onClick={() => setDetailModal(true)}
                                    className="time-period-modal__link-registration"
                                >
                                    Bekijk de inschrijvingen <MaterialIcon>arrow_forward</MaterialIcon>
                                </a>

                                {renderError("customerOnly", "Max. aantal mensen")}
                            </div>
                        </div>
                    }

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Alleen voor klanten</p>
                            <Checkbox
                                checked={timePeriod?.customerOnly}
                                onChange={(event) => handleChange("customerOnly", event.currentTarget.checked)}
                            />
                        </div>
                    </div>

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Duur</p>
                            <TimeSelect
                                value={timePeriod?.duration}
                                onChange={(value) => handleChange("duration", value)}
                            />
                            {renderError("customerOnly", "Duur")}
                        </div>
                    </div>

                    <div className="time-period-modal__input-container">
                        <div className="time-period-modal__input-wrapper">
                            <p className="time-period-modal__label">Herhalen op</p>

                            <DaySelector />
                        </div>
                    </div>

                    {
                        timePeriod?.days && timePeriod?.days?.length > 0 &&
                        <div className="time-period-modal__input-container">
                            <div className="time-period-modal__input-wrapper">
                                <p className="time-period-modal__label">Eind datum</p>

                                <DatePicker
                                    selected={timePeriod.endDate ? new Date(timePeriod.endDate) : undefined}
                                    onChange={(date: Date) => editTimePeriod({ endDate: date ? date.toISOString() : undefined })}
                                />
                            </div>
                        </div>
                    }
                </div>

                <div className="time-period-modal__footer">
                    <div>
                        {renderError("date")}
                    </div>

                    <div className="time-period-modal__button">
                        <FilledButton
                            color="flame"
                            buttonSize="medium"
                            onClick={handleSubmit}
                        >
                            {
                                isEditing
                                    ? "Update"
                                    : "Opslaan"
                            }
                        </FilledButton>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimePeriodModal