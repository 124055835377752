import { FC } from "react";
import { days } from "../day/Day";
import { DateHelper } from "../../../../../../utils/DateHelper";

interface IProps {
    date: Date
}

const DayHeader: FC<IProps> = ({ date }) => {
    const today = new Date()

    return (
        <div className={`day-header ${date.getDay() === 6 && "day-header--last"}`}>
            <div className={`day-header__date ${DateHelper.isSameDay(today, date) && "day-header__date--today"}`}>
                <h2>{date.getDate()}</h2>
                <p>{days[date.getDay()]}</p>
            </div>
        </div>
    )
}

export default DayHeader