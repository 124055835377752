import { FC, useState } from "react";
import MaterialIconOutlined from "../../../../components/Icons/MaterialIconOutlined";
import { useGetChatsQuery } from "../../../../../redux/slices/chats/ChatsApiSlice";
import { ReactComponent as EmptyChatImage } from "../../../../assets/svg/no-chat.svg";
import ElevatedButton from "../../../../components/Buttons/ElevatedButton";
import NewChat from "../modals/new-chat/NewChat";
import { useUserId } from "../../../../../redux/slices/user/UserSlice";
import AvatarPlaceholder from "../../../../components/AvatarPlaceholder/AvatarPlaceholder";

interface IProps {
    setCurrentChat: (id: string) => void
}

const ChatList: FC<IProps> = ({
    setCurrentChat
}) => {
    const userId = useUserId();

    const [showModal, setShowModal] = useState<boolean>(false)

    const {
        data: chats
    } = useGetChatsQuery({})

    return (
        <div className="chat-list">
            <NewChat
                show={showModal}
                onClose={() => setShowModal(false)}
                setChatId={setCurrentChat}
            />

            <ElevatedButton
                color="flame"
                iconOnly
                withIcon
                className="chat-list__create-chat-button"
                onClick={() => setShowModal(true)}
            >
                <MaterialIconOutlined>edit</MaterialIconOutlined>
            </ElevatedButton>

            {
                chats?.length === 0 &&
                <div className="chat-list__empty-placeholder">
                    <EmptyChatImage />
                    <p>Nog geen berichten!</p>
                </div>
            }

            {
                chats?.map(chat => {
                    const customer = chat?.participants.filter(p => p.id !== userId)[0]

                    return (
                        <div className="chat-list__chat" onClick={() => setCurrentChat(chat.id)}>
                            <AvatarPlaceholder />

                            <p className="chat-list__chat-name">{customer.firstName} {customer.lastName}</p>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default ChatList