import { FC, useRef } from "react";
import MaterialIcon from "../../Icons/MaterialIcon";

interface IProps {
    checked?: boolean
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const Checkbox: FC<IProps> = ({ checked, onChange }) => {
    const checkboxRef = useRef<HTMLInputElement>(null)

    return (
        <>
            <div
                className={`checkbox ${checked && "checkbox--checked"}`}
                onClick={() => checkboxRef.current?.click()}
            >
                <MaterialIcon>check</MaterialIcon>
            </div>

            <input
                type="checkbox"
                checked={checked}
                ref={checkboxRef}
                hidden
                onChange={onChange}
            />
        </>
    )
}

export default Checkbox