import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../types'
import { ExpertInvite } from './ExpertInvite'
import { BadRequest } from '../../types/BadRequest'

export const identityApi = createApi({
    reducerPath: 'identityApi',
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, { getState }) => {
            const user = (getState() as RootState).userSlice.user

            if (user) {
                headers.set('Authorization', `Bearer ${user.access_token}`)
            }

            return headers
        },
        baseUrl: process.env.REACT_APP_AUTHSERVER_ENDPOINT + '/api'
    }),
    endpoints: (builder) => ({
        inviteExpert: builder.mutation<void, ExpertInvite>({
            query: (invite) => ({
                url: "/experts",
                method: "POST",
                body: {
                    ...invite,
                    trialEndDate: invite.trialEndDate.toISOString()
                }
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            }
        }),
        deleteExpert: builder.mutation<void, string>({
            query: (id) => ({
                url: `/experts/${id}`,
                method: "DELETE"
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            }
        })
    })
})

export const { useInviteExpertMutation, useDeleteExpertMutation } = identityApi