import { IRouteDef } from "./IRouteDef"

interface IExpertRoutes {
    // dashboard: IRouteDef,
    calendar: IRouteDef,
    customers: IRouteDef,
    chats: IRouteDef,
    profile: IRouteDef
}

export const ExpertRoutes: Record<keyof IExpertRoutes, IRouteDef> = {
    // dashboard: {
    //     name: "Dashboard",
    //     path: "/",
    //     iconName: "dashboard",
    //     iconOutlined: false,
    //     sidebar: true
    // },
    calendar: {
        name: "Agenda",
        path: "/calendar",
        iconName: "calendar_month",
        iconOutlined: false,
        sidebar: true
    },
    customers: {
        name: "Klanten",
        path: "/customers",
        iconName: "group",
        iconOutlined: true,
        sidebar: true
    },
    chats: {
        name: "Chats",
        path: "/chats",
        iconName: "chat_bubble",
        iconOutlined: true,
        sidebar: true
    },
    profile: {
        name: "Profiel",
        path: "/profile",
        iconName: "account_circle",
        iconOutlined: false,
        sidebar: true
    }
}