import { FC, PropsWithChildren } from "react";
import { SidebarContextProvider } from "./SidebarContext";

const Contexts: FC<PropsWithChildren> = ({ children }) => {
    return (
        <SidebarContextProvider>
            {children}
        </SidebarContextProvider>
    )
}

export default Contexts