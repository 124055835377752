import { FC } from "react"
import Select from "../Select/Select"

interface IProps {
    onChange: (value: number) => void
    value?: number
}

const TimeSelect: FC<IProps> = ({ onChange, value }) => {
    const options = Array.from(Array(32)).map((_, index) => 15 * (index + 1))

    return (
        <Select
            onChange={onChange}
            value={value}
            options={
                options.map(totalMinutes => {
                    const hours = totalMinutes / 60
                    const minutes = totalMinutes % 60

                    return { text: `${Math.floor(hours)} uur, ${minutes} minuten`, value: totalMinutes }
                })
            }
        />
    )
}

export default TimeSelect