import { FC, useMemo, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import AccountProvider from "../../../../providers/AccountProvider"
import BadRequest from "../../../../providers/exceptions/BadRequest"
import MaterialIcon from "../../../components/Icons/MaterialIcon"
import Input from "../../../components/Inputs/Input/Input"
import FilledButton from "../../../components/Buttons/FilledButton"

const ResetPasswordPage: FC = () => {
    const [passwords, setPasswords] = useState<{
        password: string, passwordConfirmation: string
    }>({ password: "", passwordConfirmation: "" })

    const authServerEndpoint = process.env.REACT_APP_AUTHSERVER_ENDPOINT

    const [isReset, setIsReset] = useState<boolean>(false)
    const [error, setError] = useState<string>()

    const location = useLocation()
    const navigate = useNavigate()

    const searchParams = useMemo(() => new URLSearchParams(location.search.substring(1)), [location.search])

    const email = useMemo(() => searchParams.get("email"), [searchParams])

    const resetPassword = () => {
        const params = new URLSearchParams(location.search.substring(1))

        if (passwords.password !== passwords.passwordConfirmation) return setError("Wachtwoorden komen niet overeen.")
        if (passwords.password === "" || passwords.passwordConfirmation === "") return setError("Wachtwoordveld kan niet leeg zijn.")

        const token = params.get("token")
        if (token === null || email === null) return navigate("/")

        const accountProvider = new AccountProvider()
        accountProvider.resetPassword(email, passwords?.password, token)
            .then(() => setIsReset(true))
            .catch(e => {
                if (e instanceof BadRequest) {
                    if (e.message === "InvalidToken") {
                        setError("InvalidToken")
                    } else {
                        setError(e.message)
                    }
                } else {
                    setError(e.toStng())
                }
            })
    }

    return (
        <div className="page reset-password-page">
            <div className="reset-password-page__container">
                {
                    isReset &&
                    <div className="reset-password-page__overlay">
                        <h1>Gelukt!</h1>

                        <p>Je kunt nu inloggen met jouw nieuwe wachtwoord.</p>

                        <Link to="/">
                            <FilledButton color="flame" withIcon>
                                <MaterialIcon>login</MaterialIcon> Inloggen
                            </FilledButton>
                        </Link>
                    </div>
                }
                <h1>Wachtwoord instellen</h1>

                {
                    error !== "InvalidToken" &&
                    <>
                        <Input
                            className="form-input form-input--disabled"
                            placeholder="Email"
                            value={email ?? ""}
                            disabled
                        />
                        <Input
                            className="form-input"
                            placeholder="Wachtwoord"
                            type="password"
                            onChange={(e) => setPasswords(prev => ({ ...prev, password: e.target.value }))}
                            value={passwords.password}
                        />
                        <Input
                            className="form-input"
                            placeholder="Wachtwoord bevestigen"
                            type="password"
                            onChange={(e) => setPasswords(prev => ({ ...prev, passwordConfirmation: e.target.value }))}
                            value={passwords.passwordConfirmation}
                        />
                    </>
                }

                {error && error !== "InvalidToken" && <label className="form-input__error-message">{error}</label>}

                {
                    error === "InvalidToken"
                        ?
                        <>
                            <p>Deze link is helaas verlopen. Klik <a href={`${authServerEndpoint}/account/requestpasswordreset`}>hier</a> om een nieuwe link aan te vragen. Excuses voor het ongemak.</p>
                        </>
                        : <FilledButton color="flame" onClick={resetPassword}>Instellen</FilledButton>
                }
            </div>
        </div>
    )
}

export default ResetPasswordPage