import { FC, useContext } from "react"
import { useUserId } from "../../../../../../../redux/slices/user/UserSlice"
import { useGetAppointmentsOnDateQuery } from "../../../../../../../redux/slices/experts/time-period/TimePeriodApiSlice"
import Loader from "../../../../../../components/Loader/Loader"
import CalendarContext from "../../../context/CalendarContext"

interface IProps {
    date?: string
}

const Appointments: FC<IProps> = ({ date }) => {
    const userId = useUserId()

    const { originalTimePeriod } = useContext(CalendarContext)

    const {
        data: appointments,
        isLoading
    } = useGetAppointmentsOnDateQuery({
        expertId: userId,
        timePeriodId: originalTimePeriod?.id ?? "",
        date: originalTimePeriod?.date ?? ""
    }, {
        skip: !originalTimePeriod?.id || !originalTimePeriod.date
    })

    return (
        <div className="time-period-detail__appointments time-period-detail__input-container">
            <div className="time-period-detail__input-content">
                <p className="time-period-detail__input-label">Inschrijvingen</p>

                {
                    isLoading &&
                    <Loader />
                }

                {
                    appointments?.map(a => (
                        <div className="time-period-detail__registration">
                            <p>
                                <span className="time-period-detail__registration-name">{a.fitUser.firstName} {a.fitUser.lastName}</span>
                            </p>
                            <i><a href={`mailto:${a.fitUser.email}`}>{a.fitUser.email}</a></i>
                        </div>
                    ))
                }

                {appointments?.length === 0 && <p>Geen inschrijvingen.</p>}
            </div>
        </div>
    )
}

export default Appointments