import { BadRequest } from '../../types/BadRequest'
import { api } from '../api/ApiSlice'
import { Chat } from './Chat'

const chatApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getChats: builder.query<Chat[], {}>({
            query: () => `/chats`,
            providesTags: (result) =>
                result
                    ? [
                        ...result.map((item) => ({ type: 'Chat', id: item.id } as const)),
                        { type: 'Chat', id: 'LIST' },
                    ]
                    : [{ type: 'Chat', id: 'LIST' }],
        }),
        createChat: builder.mutation<Chat, string>({
            query: (userId) => ({
                url: "/chats",
                method: "POST",
                body: { userId },
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            },
            invalidatesTags: [{ type: 'Chat', id: 'LIST' }],
        }),
        getChat: builder.query<Chat, string>({
            query: (id) => `/chats/${id}`,
            providesTags: (result) => result ? [{ type: 'Chat', id: result.id }] : []
        }),
        sendMessage: builder.mutation<void, { id: string, message: string }>({
            query: ({ id, message }) => ({
                url: `/chats/${id}/messages`,
                method: "POST",
                body: { message }
            }),
            invalidatesTags: (_result, _error, args) => [{ type: 'Chat', id: args.id }]
        })
    }),
})

export const {
    useGetChatsQuery,
    useGetChatQuery,
    useSendMessageMutation,
    useCreateChatMutation,
} = chatApi