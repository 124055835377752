import { BadRequest } from "../../../types/BadRequest";
import { api } from "../../api/ApiSlice";
import { Expert } from "../users/Expert";
import { ExpertRequest } from "../ExpertRequest";
import { UpdateExpertProfileRequest } from "./UpdateExpertProfileRequest";

const expertProfileApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getExpertProfile: builder.query<Expert, string>({
            query: (expertId) => `/experts/${expertId}/profile`,
            transformResponse: (result: Expert) => {
                result.avatarUrl = `${process.env.REACT_APP_API_ENDPOINT}/experts/${result.id}/profile/avatar`

                return result
            },
            providesTags: ['Expert']
        }),
        updateExpertProfile: builder.mutation<string, UpdateExpertProfileRequest>({
            query: ({ expertId, data }) => ({
                url: `/experts/${expertId}/profile`,
                method: "POST",
                body: data,
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            },
            invalidatesTags: ['Expert']
        }),
        uploadAvatar: builder.mutation<string, ExpertRequest<FormData>>({
            query: ({ expertId, data }) => ({
                url: `/experts/${expertId}/profile/avatar`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ['Expert']
        }),
        uploadGallery: builder.mutation<string, ExpertRequest<FormData>>({
            query: ({ expertId, data }) => ({
                url: `/experts/${expertId}/profile/gallery`,
                method: "POST",
                body: data,
            }),
            invalidatesTags: ['Expert']
        }),
        deleteGalleryImage: builder.mutation<void, ExpertRequest<string>>({
            query: ({ expertId, data }) => ({
                url: `/experts/${expertId}/profile/gallery/${data}`,
                method: "DELETE",
            }),
            invalidatesTags: ['Expert']
        })
    }),
})

export const {
    useGetExpertProfileQuery,
    useUpdateExpertProfileMutation,
    useUploadAvatarMutation,
    useUploadGalleryMutation,
    useDeleteGalleryImageMutation
} = expertProfileApiSlice