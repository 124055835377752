import { FC, useState } from "react"
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextButton from "../../../../../components/Buttons/TextButton"
import MaterialIcon from "../../../../../components/Icons/MaterialIcon"
import Modal from "../../../../shared/modals/Modal"
import { useDeleteTagMutation } from "../../../../../../redux/slices/tag/TagApiSlice"

interface IProps {
    item: string
}

const Delete: FC<IProps> = ({ item }) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const [deleteTag, { isLoading, isSuccess }] = useDeleteTagMutation()

    return (
        <>
            <TextButton
                color="flame"
                onClick={() => setShowModal(true)}
                withIcon
                iconOnly
            >
                <MaterialIcon>delete</MaterialIcon>
            </TextButton>

            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                className="tags-page__delete"
                isLoading={isLoading}
                isSuccess={isSuccess}
            >
                <div>
                    <h1 className="tags-page__delete-title">Verwijderen</h1>

                    <p className="tags-page__delete-text">Weet je zeker dat je de tag "{item}" wilt verwijderen?</p>
                </div>

                <div className="tags-page__delete-buttons">
                    <FilledButton
                        color="flame"
                        className="tags-page__delete-button"
                        onClick={() => deleteTag(item)}
                    >
                        Bevestigen
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="tags-page__delete-button"
                        onClick={() => setShowModal(false)}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default Delete