import { FC, useContext, useMemo, useRef } from "react";
import TimePeriodBlock from "../time-period-block/TimePeriodBlock";
import Portal from "../../../../shared/portal/Portal";
import TimePeriodModal from "../../modals/time-period/TimePeriodModal";
import CalendarContext from "../../context/CalendarContext";

export const days = [
    "Zon",
    "Maa",
    "Din",
    "Woe",
    "Don",
    "Vri",
    "Zat",
]

interface IProps {
    date: Date,
    headerTopY?: number
    containerHeight?: number
}

const Day: FC<IProps> = ({
    date,
    headerTopY,
    containerHeight
}) => {
    const dayRef = useRef<HTMLDivElement>(null)

    const { timePeriod, setNewTimePeriod, timePeriods, occurrenceDate } = useContext(CalendarContext)

    const isMobile = window.matchMedia("(max-width: 900px)").matches

    const handleClick = (_: React.MouseEvent<HTMLDivElement, MouseEvent>, hour: number) => {
        setNewTimePeriod(new Date(date.getFullYear(), date.getMonth(), date.getDate(), hour))
    }


    const isTimePeriodThisDay = timePeriod
        ? occurrenceDate !== undefined
            ? date.getDay() === occurrenceDate
            : date.getDay() === new Date(timePeriod.startDate).getDay()
        : false

    const containerBoundingClientRect = useMemo(() => {
        if (!isTimePeriodThisDay || !dayRef.current) return undefined

        return {
            x: dayRef.current.getBoundingClientRect().x,
            width: dayRef.current.getBoundingClientRect().width
        }
    }, [dayRef, isTimePeriodThisDay])

    const timePeriodsToday = useMemo(() => {
        const beginToday = new Date(date)
        beginToday.setHours(0, 0, 0)

        const endToday = new Date(date)
        endToday.setHours(23, 59, 59)

        if (timePeriods) return timePeriods.filter(tp => tp.date ? new Date(tp.date) >= beginToday && new Date(tp.date) <= endToday : false)
    }, [timePeriods, date])

    const now = new Date()
    const nowMinutes = now.getHours() * 60 + now.getMinutes()
    const timeIndicatorPosY = dayRef.current?.getBoundingClientRect().height! / (24 * 60) * (nowMinutes)

    return (
        <>
            {
                isTimePeriodThisDay && !isMobile &&
                <Portal>
                    <TimePeriodModal
                        positionX={containerBoundingClientRect?.x}
                        positionY={headerTopY}
                        containerWidth={containerBoundingClientRect?.width}
                        containerMaxHeight={containerHeight}
                    />
                </Portal>
            }

            <div
                className={`day ${date.getDay() === 6 && "day--last"}`}
                ref={dayRef}
            >
                {
                    date.toDateString() === now.toDateString() &&
                    <div className="day__time-indicator" style={{ top: Number.isNaN(timeIndicatorPosY) ? undefined : timeIndicatorPosY }}>
                        <div className="day__time-indicator-triangle"></div>
                    </div>
                }

                {
                    Array.from(Array(24)).map((_, index) => {
                        return (
                            <div
                                key={`${date.toISOString()}-${index}`}
                                className="day__hour"
                                onClick={(e) => handleClick(e, index)}
                            >
                                {
                                    timePeriod &&
                                    !timePeriod.id &&
                                    isTimePeriodThisDay &&
                                    new Date(timePeriod.startDate).getHours() === index &&
                                    <TimePeriodBlock
                                        timePeriod={timePeriod}
                                        editing
                                    />
                                }
                                <p className="day__time">{index < 10 && "0"}{index}:00</p>
                            </div>
                        )
                    })
                }

                {
                    timePeriodsToday?.map(tp => {
                        let hours = new Date(tp.startDate).getUTCHours() - new Date(date).getTimezoneOffset() / 60

                        if (hours >= 24) {
                            hours = hours % 24
                        } else if (hours < 0) {
                            hours = 23 - hours
                        }

                        return (
                            <div
                                key={tp.id}
                                className="day__time-period-block"
                                style={{
                                    top: hours * 100 + "px"
                                }}
                            >
                                <TimePeriodBlock
                                    date={date}
                                    timePeriod={tp}
                                />
                            </div>
                        )
                    })
                }
            </div >
        </>
    )
}

export default Day