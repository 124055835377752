import { FC } from "react";
import Header from "../../shared/header/Header";
import ProfileInfo from "./profile-info/ProfileInfo";
import ProfileExtra from "./profile-extra/ProfileExtra";

const ProfilePage: FC = () => {
    return (
        <div className="page profile-page">
            <Header>
                <h1>Profiel</h1>
            </Header>

            <div className="profile-page__content">
                <ProfileInfo />

                <ProfileExtra />
            </div>
        </div>
    )
}

export default ProfilePage