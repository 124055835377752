import { FC } from "react";
import { ExpertImage } from "../../../../../../redux/slices/experts/users/ExpertImage";
import Delete from "./modals/Delete";

interface IProps {
    expertId: string
    image: ExpertImage
}

const Picture: FC<IProps> = ({ expertId, image }) => {
    const imageUrl = `${process.env.REACT_APP_API_ENDPOINT}/experts/${expertId}/profile/gallery/${image.id}`

    return (
        <div className="profile-extra__picture">
            <img src={imageUrl} alt="" />

            <Delete image={image} />
        </div>
    );
}

export default Picture