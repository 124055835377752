// React
import { FC, useContext } from "react"
import { Link, useLocation } from "react-router-dom"

// Redux
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks"
import { signOutAsync } from "../../../../../redux/slices/user/UserSlice"

// Routes
import { ExpertRoutes } from "../../../../../routes/ExpertRoutes"
import { AdminRoutes } from "../../../../../routes/AdminRoutes"

// Assets
import MaterialIcon from "../../../../components/Icons/MaterialIcon"
import MaterialIconOutlined from "../../../../components/Icons/MaterialIconOutlined"
import { Logo } from "../../../../components/Logo/Logo"

// Constants
import { Roles } from "../../../../../constants/Roles"
import FilledButton from "../../../../components/Buttons/FilledButton"
import { SidebarContext } from "../../../../contexts/SidebarContext"

const Sidebar: FC = () => {
    const user = useAppSelector(state => state.userSlice.user)
    const dispatch = useAppDispatch()

    const location = useLocation()

    const isMobile = window.matchMedia("(max-width: 900px)").matches

    const { sidebarOpen, toggleSidebar } = useContext(SidebarContext)

    return (
        <div className={`sidebar ${sidebarOpen && "sidebar--open"}`}>
            <div className={`sidebar__title ${sidebarOpen && "sidebar__title--open"}`}>
                <button
                    className="sidebar__menu-button"
                    onClick={toggleSidebar}
                >
                    <MaterialIcon className="sidebar__menu-icon">close</MaterialIcon>
                </button>

                <Logo.Small className="sidebar__logo" />

                <p className="sidebar__fit-buddy">FIT BUDDY</p>
            </div>

            {
                user?.profile.role === Roles.expert &&
                <div className="sidebar__link-container">
                    {
                        Object.entries(ExpertRoutes)
                            .filter(route => route[1].sidebar)
                            .map((route, index) => {
                                return (
                                    <Link
                                        to={route[1].path}
                                        className={`sidebar__route ${location.pathname === route[1].path && "sidebar__route--selected"}`}
                                        onClick={isMobile ? toggleSidebar : undefined}
                                        key={`route-${index}`}
                                    >
                                        {
                                            route[1].iconOutlined ?
                                                <MaterialIconOutlined>{route[1].iconName}</MaterialIconOutlined>
                                                :
                                                <MaterialIcon>{route[1].iconName}</MaterialIcon>
                                        }

                                        {route[1].name}
                                    </Link>
                                )
                            })
                    }
                </div>
            }

            {
                user?.profile.role === Roles.admin &&
                <div className="sidebar__link-container">
                    {
                        Object.entries(AdminRoutes)
                            .filter(route => route[1].sidebar)
                            .map((route, index) => {
                                return (
                                    <Link
                                        to={route[1].path}
                                        className={`sidebar__route ${location.pathname === route[1].path && "sidebar__route--selected"}`}
                                        key={`route-${index}`}
                                    >
                                        {
                                            route[1].iconOutlined ?
                                                <MaterialIconOutlined>{route[1].iconName}</MaterialIconOutlined>
                                                :
                                                <MaterialIcon>{route[1].iconName}</MaterialIcon>
                                        }

                                        {route[1].name}
                                    </Link>
                                )
                            })
                    }
                </div>
            }
            <div className="sidebar__bottom">
                <FilledButton
                    color="flame"
                    className={`sidebar__logout ${sidebarOpen && "sidebar__logout--open"}`}
                    onClick={() => dispatch(signOutAsync())}
                    withIcon={!sidebarOpen}
                    iconOnly={!sidebarOpen}
                >
                    <MaterialIcon>logout</MaterialIcon>
                    {sidebarOpen && <p>Uitloggen</p>}
                </FilledButton>

                <a href="mailto:support@fitbuddy.club" className="sidebar__contact" target="_blank" rel="noreferrer">
                    {
                        sidebarOpen ?
                            "Contact us."
                            :
                            <MaterialIconOutlined>mail</MaterialIconOutlined>
                    }
                </a>
            </div>
        </div>
    )
}

export default Sidebar