import { ChangeEvent, FC, useState } from "react";
import FilledButton from "../../../../../components/Buttons/FilledButton";
import MaterialIcon from "../../../../../components/Icons/MaterialIcon";
import Modal from "../../../../shared/modals/Modal";
import TextButton from "../../../../../components/Buttons/TextButton";
import Input from "../../../../../components/Inputs/Input/Input";
import { useInviteExpertMutation } from "../../../../../../redux/slices/identity/IdentityApiSlice";
import { ExpertInvite } from "../../../../../../redux/slices/identity/ExpertInvite";
import ElevatedButton from "../../../../../components/Buttons/ElevatedButton";

const Invite: FC = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [invite, setInvite] = useState<ExpertInvite>(new ExpertInvite())

    const [
        inviteExpert,
        {
            isLoading,
            isSuccess,
            error,
            isError,
            reset
        }
    ] = useInviteExpertMutation()

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target
        const name = target.name
        const value = target.value

        switch (e.target.type) {
            case "date": {
                setInvite(val => ({ ...val, [name]: new Date(value) }))
                break
            }
            default: {
                setInvite(val => ({ ...val, [name]: e.target.value }))
            }
        }
    }

    const onClose = () => {
        reset()
        setShowModal(false)
        setInvite(new ExpertInvite())
    }

    return (
        <>
            <ElevatedButton
                color="flame"
                size="large"
                className="experts-page__invite-button"
                withIcon
                onClick={() => setShowModal(true)}
            >
                <MaterialIcon>mail</MaterialIcon> Uitnodigen
            </ElevatedButton>

            <Modal
                show={showModal}
                onClose={onClose}
                className="experts-page__create"
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={isError}
            >
                <div>
                    <h1 className="experts-page__create-title">Expert uitnodigen</h1>

                    <div className="experts-page__inputs-wrapper">

                        <div className="experts-page__name-inputs">
                            <Input
                                className="experts-page__create-input"
                                name="firstName"
                                value={invite.firstName}
                                onChange={onChange}
                                placeholder="Voornaam"
                                error={error}
                            />

                            <Input
                                className="experts-page__create-input"
                                name="lastName"
                                value={invite.lastName}
                                onChange={onChange}
                                placeholder="Achternaam"
                                error={error}
                            />
                        </div>

                        <Input
                            className="experts-page__create-input"
                            name="email"
                            value={invite.email}
                            onChange={onChange}
                            placeholder="Email"
                            error={error}
                        />

                        <Input
                            className="experts-page__create-input"
                            name="trialEndDate"
                            value={invite.trialEndDate
                                ? `${invite.trialEndDate.getFullYear()}-${invite.trialEndDate.getMonth() + 1 < 10 ? "0" : ""}${invite.trialEndDate.getMonth() + 1}-${invite.trialEndDate.getDate() < 10 ? "0" : ""}${invite.trialEndDate.getDate()}`
                                : undefined}
                            onChange={onChange}
                            type="date"
                            error={error}
                        />
                    </div>
                </div>

                <div className="experts-page__create-buttons">
                    <FilledButton
                        color="flame"
                        className="experts-page__create-button"
                        onClick={() => inviteExpert(invite)}
                    >
                        Opslaan
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="experts-page__create-button"
                        onClick={onClose}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default Invite