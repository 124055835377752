import { FC, useState } from "react"
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextButton from "../../../../../components/Buttons/TextButton"
import MaterialIcon from "../../../../../components/Icons/MaterialIcon"
import Modal from "../../../../shared/modals/Modal"
import { Expert } from "../../../../../../redux/slices/experts/users/Expert"
import { useDeleteExpertMutation } from "../../../../../../redux/slices/identity/IdentityApiSlice"

interface IProps {
    item: Expert,
    onSuccess: () => void
}

const Delete: FC<IProps> = ({ item }) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const [deleteExpert, { isLoading, isSuccess }] = useDeleteExpertMutation()

    return (
        <>
            <TextButton
                color="flame"
                onClick={() => setShowModal(true)}
                withIcon
                iconOnly
            >
                <MaterialIcon>person_remove</MaterialIcon>
            </TextButton>

            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                className="tags-page__delete"
                isLoading={isLoading}
                isSuccess={isSuccess}
            >
                <div>
                    <h1 className="tags-page__delete-title">Verwijderen</h1>

                    <p className="tags-page__delete-text">Weet je zeker dat je "{item.firstName} {item.lastName}" wilt verwijderen?</p>
                </div>

                <div className="tags-page__delete-buttons">
                    <FilledButton
                        color="flame"
                        className="tags-page__delete-button"
                        onClick={() => deleteExpert(item.id)}
                    >
                        Bevestigen
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="tags-page__delete-button"
                        onClick={() => setShowModal(false)}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default Delete