import { BadRequest } from "../../../types/BadRequest";
import { Paginated } from "../../../types/Paginated";
import { PaginatedRequest } from "../../../types/PaginatedRequest";
import { api } from "../../api/ApiSlice";
import { ExpertRequest } from "../ExpertRequest";
import { Customer } from "./Customer";

const expertCustomerApiSlice = api.injectEndpoints({
    endpoints: (builder) => ({
        getCustomers: builder.query<Paginated<Customer>, ExpertRequest<PaginatedRequest>>({
            query: ({ expertId, data: { page, pageSize: size, } }) =>
                `/experts/${expertId}/customers?page=${page}&pageSize=${size}`,
            providesTags: (result) =>
                result
                    ?
                    [
                        ...result.items.map((item) => ({ type: 'Customer', item } as const)),
                        { type: 'Customer', id: 'LIST' },
                    ]
                    :
                    [{ type: 'Customer', id: 'LIST' }],
        }),
        inviteCustomer: builder.mutation<string, ExpertRequest<string>>({
            query: ({ expertId, data: email }) => ({
                url: `/experts/${expertId}/customer-invites`,
                method: "POST",
                body: { email },
            }),
            transformErrorResponse: (error) => {
                if (error.status === 400) {
                    error.data = (error.data as BadRequest).errors
                }

                return error
            },
        }),
    }),
})

export const {
    useGetCustomersQuery,
    useInviteCustomerMutation,
} = expertCustomerApiSlice