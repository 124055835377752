import { FC, useContext } from "react";
import CalendarContext from "../../../context/CalendarContext";

const dayNames = [
    "Zo",
    "Ma",
    "Di",
    "Wo",
    "Do",
    "Vr",
    "Za"
]

const DaySelector: FC = () => {
    const { editTimePeriod, timePeriod } = useContext(CalendarContext)

    // Days-array is saved in UTC as well. E.g. 24 june 23:00:00 UTC is saturday. Timezone with +02:00 is sunday.
    const isSelected = (index: number) => {
        if (!timePeriod) return false

        const hours = new Date(timePeriod?.startDate).getUTCHours() + -(new Date().getTimezoneOffset() / 60)

        if (hours > 23) {
            return timePeriod.days?.map(d => d + 1 > 6 ? 0 : d + 1).includes(index)
        }

        if (hours < 0) {
            return timePeriod.days?.map(d => d - 1 < 0 ? 6 : d - 1).includes(index)
        }

        return timePeriod?.days?.includes(index)
    }

    const onSelect = (index: number) => {
        if (!timePeriod) return false

        let dayIndex = index;

        const hours = new Date(timePeriod?.startDate).getUTCHours() + -(new Date().getTimezoneOffset() / 60)

        if (hours > 23) {
            dayIndex = dayIndex - 1 < 0 ? 6 : dayIndex - 1
        }

        if (hours < 0) {
            dayIndex = dayIndex + 1 > 6 ? 0 : dayIndex + 1;
        }

        editTimePeriod({
            days: (
                timePeriod?.days
                    ? isSelected(index)
                        ? timePeriod?.days.filter(d => d !== dayIndex)
                        : [...timePeriod?.days, dayIndex]
                    : [dayIndex]
            ).sort((a, b) => a - b)
        })
    }

    return (
        <div className="day-selector">
            {
                dayNames.map((day, index) => {
                    let selected = timePeriod?.days?.includes(index)

                    return (
                        <div
                            key={`day-${index}`}
                            className={`day-selector__day ${isSelected(index) && "day-selector__day--selected"}`}
                            onClick={() => onSelect(index)}
                        >
                            {day}
                        </div>
                    )
                })
            }
        </div >
    )
}

export default DaySelector