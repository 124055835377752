import { FC, PropsWithChildren } from "react";
import MenuIcon from "./menu-icon/MenuIcon";
import MaterialIcon from "../../../components/Icons/MaterialIcon";
import { useNavigate } from "react-router-dom";

interface IProps extends PropsWithChildren {
    button?: "sidebar" | "back" | "close",
    onClick?: () => void
}

const Header: FC<IProps> = ({ children, button = "sidebar", onClick }) => {
    const navigate = useNavigate();

    const renderButton = () => {
        switch (button) {
            case "close": {
                return (
                    <MaterialIcon
                        className="menu-icon"
                        onClick={onClick}
                    >
                        close
                    </MaterialIcon>
                )
            }
            case "back": {
                return (
                    <MaterialIcon
                        className="menu-icon"
                        onClick={() => navigate(-1)}
                    >
                        arrow_back
                    </MaterialIcon>
                )
            }
            default: {
                return <MenuIcon />
            }
        }
    }

    return (
        <div className="header">
            {
                renderButton()
            }

            <div className="header__inner">
                {children}
            </div>
        </div>
    )
}

export default Header