import { FC } from "react";
import Header from "../../shared/header/Header";
import Table from "../../shared/table/Table";
import { Customer } from "../../../../redux/slices/experts/customer/Customer";
import { useNavigate, useSearchParams } from "react-router-dom";
import InviteModal from "./modals/invite/InviteModal";
import MaterialIcon from "../../../components/Icons/MaterialIcon";
import TextButton from "../../../components/Buttons/TextButton";
import { ExpertRoutes } from "../../../../routes/ExpertRoutes";
import { useGetCustomersQuery } from "../../../../redux/slices/experts/customer/ExpertCustomerApiSlice";
import { useUserId } from "../../../../redux/slices/user/UserSlice";

const CustomersPage: FC = () => {
    const [searchParams] = useSearchParams()
    const navigate = useNavigate()

    const userId = useUserId();

    const pageSize = 10;
    const page = parseInt(searchParams.get("page") ?? "0")

    const { data: customers,
        isLoading,
        isFetching
    } = useGetCustomersQuery({
        expertId: userId,
        data: {
            page,
            pageSize
        }
    })

    return (
        <div className="page customers-page">
            <Header>
                <h1>Klanten</h1>

                <InviteModal />
            </Header>

            <div className="customers-page__content content">
                <div className="customers-page__table-container">
                    <Table<Customer>
                        className={"customers-page__table"}
                        columns={[
                            {
                                name: "Voornaam",
                                render: (item) => item.firstName + " " + item.lastName,
                                className: "customers-page__name-column"
                            },
                            {
                                name: "Email",
                                render: (item) => item.email,
                                className: "customers-page__email-column",
                                mobile: false
                            },
                            {
                                name: "",
                                render: (item) => (
                                    <>
                                        <TextButton
                                            color="flame"
                                            withIcon
                                            iconOnly
                                            onClick={() => navigate(ExpertRoutes.chats.path + `#${item.email}`)}
                                        >
                                            <MaterialIcon>chat_bubble</MaterialIcon>
                                        </TextButton>
                                    </>
                                ),
                                className: "customers-page__actions-column",
                                mobile: false
                            }
                        ]}
                        items={customers}
                        isLoading={isLoading}
                        isFetching={isFetching}
                        page={page}
                        pageSize={pageSize}
                        totalItems={customers?.totalItems ?? 0}
                    />
                </div>
            </div>
        </div>
    )
}

export default CustomersPage