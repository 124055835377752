// React
import { FC, PropsWithChildren, useEffect, useRef } from "react";

// Redux
import { AuthState } from "../../../../redux/slices/user/AuthState";
import { loadUserAsync } from "../../../../redux/slices/user/UserSlice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

interface IProps extends PropsWithChildren {
    roles?: string[]
}

const Restricted: FC<IProps> = ({ roles, children }) => {
    const userState = useAppSelector(state => state.userSlice)
    const dispatch = useAppDispatch()

    const initialized = useRef(false)

    useEffect(() => {
        switch (userState?.status) {
            case AuthState.notAuthenticated: {
                if (!initialized.current) {
                    dispatch(loadUserAsync())

                    initialized.current = true
                }
                break;
            }
        }
    }, [userState, dispatch])

    if(userState.status === AuthState.notAuthenticated) return <></>

    return (
        <>
            {children}
        </>
    )
}

export default Restricted