import BadRequest from "./exceptions/BadRequest"
import Forbidden from "./exceptions/Forbidden"
import InternalServerError from "./exceptions/InternalServerError"
import NotFound from "./exceptions/NotFound"
import Unauthorized from "./exceptions/Unauthorized"

interface RequestParameters extends RequestInit {
  isJson?: boolean
  path?: string
  queryString?: string
}

abstract class BaseDataProvider {
  public url: string
  public bearerToken?: string

  constructor(path?: string, bearerToken?: string, url?: string) {
    this.bearerToken = bearerToken

    if (path === "") {
      this.url = `${url}`
    } else {
      this.url = `${url}/${path}`
    }
  }

  async get<T>(
    parameters: RequestParameters = { path: "", isJson: true },
  ): Promise<T> {
    return this.request<T>("GET", parameters)
  }

  async post<T>(parameters: RequestParameters): Promise<T> {
    return this.request<T>("POST", parameters)
  }

  async delete<T>(parameters: RequestParameters): Promise<T> {
    return this.request<T>("DELETE", parameters)
  }

  async patch<T>(parameters: RequestParameters): Promise<T> {
    return this.request<T>("PATCH", parameters)
  }

  async put<T>(parameters: RequestParameters): Promise<T> {
    return this.request<T>("PUT", parameters)
  }

  async request<T>(method: string, parameters: RequestParameters): Promise<T> {
    if (parameters.isJson === undefined || parameters.isJson === null)
      parameters.isJson = true

    const queryString =
      parameters.queryString === undefined
        ? ""
        : `?${new URLSearchParams(parameters.queryString).toString()}`

    let { headers } = parameters

    const { isJson } = parameters
    if (isJson) headers = { ...headers, ...this.getContentType() }

    return await fetch(
      `${this.url}${
        parameters.path ? `/${parameters.path}` : ""
      }${queryString}`,
      {
        ...parameters,
        method: method,
        headers: {
          ...this.getAuthorizationHeader(),
          ...headers,
        },
      },
    ).then(async (response) => {
      if (!response.ok) {
        switch (response.status) {
          case 400:
            throw new BadRequest(await response.json())
          case 401:
            throw new Unauthorized()
          case 403:
            throw new Forbidden()
          case 404:
            throw new NotFound()
          case 500:
            throw new InternalServerError()
        }

        throw new Error(await response.json())
      }

      if (response.headers.get("Content-type")?.indexOf("text/plain") !== -1) {
        return response.text()
      }

      if (
        response.headers.get("Content-type")?.indexOf("application/json") !== -1
      ) {
        return response.json()
      }

      return response
    })
  }

  getAuthorizationHeader(): Record<string, string> {
    return {
      Authorization: `Bearer ${this.bearerToken ? this.bearerToken : ""}`,
    }
  }

  getContentType(): Record<string, string> {
    return {
      "Content-Type": "application/json",
    }
  }
}

export default BaseDataProvider
