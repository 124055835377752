import { FC, useEffect, useState } from "react"
import Modal from "../../../../shared/modals/Modal"
import { useGetCustomersQuery } from "../../../../../../redux/slices/experts/customer/ExpertCustomerApiSlice"
import Table from "../../../../shared/table/Table"
import { Customer } from "../../../../../../redux/slices/experts/customer/Customer"
import TextButton from "../../../../../components/Buttons/TextButton"
import { useUserId } from "../../../../../../redux/slices/user/UserSlice"
import { Logo } from "../../../../../components/Logo/Logo"
import MaterialIconOutlined from "../../../../../components/Icons/MaterialIconOutlined"
import { useCreateChatMutation } from "../../../../../../redux/slices/chats/ChatsApiSlice"
import { BadRequest } from "../../../../../../redux/types/BadRequest"

interface IProps {
    show: boolean,
    onClose: () => void
    setChatId: (id: string) => void
}

const NewChat: FC<IProps> = ({ show, onClose, setChatId }) => {
    const [page, setPage] = useState<number>(0)

    const userId = useUserId();

    const pageSize = 5

    const { data: customers,
        isLoading,
        isFetching
    } = useGetCustomersQuery({
        expertId: userId,
        data: {
            page,
            pageSize
        }
    })

    const [
        createChat,
        {
            data: chat,
            isSuccess: isCreationSuccess,
            error: creationError,
            isError: isCreationError,
            reset
        }
    ] = useCreateChatMutation()

    useEffect(() => {
        if (isCreationError) {
            const error = creationError as BadRequest

            if (error.status === 400) {
                let errorMessage = error.data["Chat"][0]

                if (error.data["Chat"] && errorMessage.startsWith("Exists")) {
                    let chatId = errorMessage.split("=")[1]

                    onClose()
                    setChatId(chatId)
                }
            }

            reset()
        }
    }, [
        isCreationError,
        creationError,
        onClose,
        setChatId,
        reset
    ])

    useEffect(() => {
        if (isCreationSuccess && chat) {
            onClose()
            setChatId(chat?.id)
            reset()
        }
    }, [
        isCreationSuccess,
        chat,
        setChatId,
        onClose,
        reset
    ])

    return (
        <Modal show={show} onClose={onClose}>
            <div className="new-chat">
                <h1 className="new-chat__heading">Nieuw gesprek</h1>

                <Table<Customer>
                    className={"new-chat__table"}
                    columns={[
                        {
                            name: "Avatar",
                            render: (_) => <>
                                <div className="new-chat__customer-avatar">
                                    <Logo.Small
                                        className="new-chat__customer-avatar-placeholder"
                                    />
                                </div>
                            </>,
                            className: "new-chat__avatar-column"
                        },
                        {
                            name: "Voornaam",
                            render: (item) => item.firstName + " " + item.lastName,
                            className: "new-chat__name-column"
                        },
                        {
                            name: "",
                            render: (item) => (
                                <>
                                    <TextButton
                                        color="flame"
                                        withIcon
                                        iconOnly
                                        onClick={() => createChat(item.userId)}
                                    >
                                        <MaterialIconOutlined>open_in_new</MaterialIconOutlined>
                                    </TextButton>
                                </>
                            ),
                            className: "new-chat__actions-column"
                        }
                    ]}
                    items={customers}
                    isLoading={isLoading}
                    isFetching={isFetching}
                    page={page}
                    pageSize={pageSize}
                    totalItems={customers?.totalItems ?? 0}
                    renderHeader={false}
                    setSearchParamsOnChange={false}
                    onPageChange={(selected) => setPage(selected)}
                />
            </div>
        </Modal>
    )
}

export default NewChat