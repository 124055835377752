import { FC, useContext } from "react"
import { SidebarContext } from "../../../../contexts/SidebarContext"

const MenuIcon: FC = () => {
    const { sidebarOpen, toggleSidebar } = useContext(SidebarContext)

    return (
        <div className={`menu-icon ${sidebarOpen && "menu-icon--open"}`} onClick={toggleSidebar}>
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}

export default MenuIcon