import type { Expert } from './Expert'
import { PaginatedSearchRequest } from '../../../types/PaginatedRequest'
import { Paginated } from '../../../types/Paginated'
import { api } from '../../api/ApiSlice'

const expertApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getExperts: builder.query<Paginated<Expert>, PaginatedSearchRequest>({
            query: ({ page, pageSize: size, search }) =>
                `/experts?page=${page}&pageSize=${size}${search ? `&term=${search}` : ""}`,
        })
    }),
})

export const { useGetExpertsQuery } = expertApi