import { FC, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { loadUserAsync, signInRedirectCallbackAsync } from "../../../../redux/slices/user/UserSlice";
import MaterialIcon from "../../../components/Icons/MaterialIcon";
import { AuthState } from "../../../../redux/slices/user/AuthState";
import { Navigate } from 'react-router-dom';

const AuthRedirectPage: FC = () => {
    const dispatch = useAppDispatch()
    const userError = useAppSelector(state => state.userSlice.error)
    const userStatus = useAppSelector(state => state.userSlice.status)

    useEffect(() => {
        if (userStatus === AuthState.notAuthenticated) dispatch(signInRedirectCallbackAsync())
    }, [dispatch, userStatus])


    if (userStatus === AuthState.authenticated) return <Navigate to={"/"} />

    return (
        <div className="page auth-redirect-page">
            {
                userError ?
                    <>
                        <p className="auth-redirect-page__error">{userError}</p>
                        <button
                            className="auth-redirect-page__button text-button text-button--flame"
                            onClick={() => dispatch(loadUserAsync())}
                        >
                            Probeer opnieuw <MaterialIcon>arrow_forward</MaterialIcon>
                        </button>
                    </>
                    :
                    <></>
            }
        </div>
    )
}

export default AuthRedirectPage