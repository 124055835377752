import { DetailedHTMLProps, FC, TextareaHTMLAttributes } from "react"

interface IProps extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> {

}

const TextBox: FC<IProps> = ({ ...props }) => {
    return (
        <textarea
            {...props}
            className={`text-box ${props.className}`}
        />
    )
}

export default TextBox