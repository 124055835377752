import { FC, useState } from "react";

// Components
import Header from "../../shared/header/Header";

// Child-components
import ChatList from "./chat-list/ChatList";
import ChatView from "./chat-view/ChatView";

const ChatsPage: FC = () => {
    const [currentChatId, setCurrentChatId] = useState<string>()

    return (
        <div className="page chats-page">
            <Header>
                <h1>Chats</h1>
            </Header>

            <div className="chats-page__content content">
                <ChatList
                    setCurrentChat={setCurrentChatId}
                />

                <div className={`${!currentChatId && "chat-view--hide"} chat-view`}>
                    {
                        currentChatId &&
                        <ChatView
                            onClose={() => setCurrentChatId(undefined)}
                            chatId={currentChatId}
                        />
                    }
                </div>
            </div>
        </div>
    )
}

export default ChatsPage