import { DetailedHTMLProps, FC, PropsWithChildren } from "react";

interface IProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, PropsWithChildren {
    color: 'flame' | 'turquoise' | 'grey'
    buttonSize?: 'small' | 'medium'
    withIcon?: boolean
    iconOnly?: boolean
}

const FilledButton: FC<IProps> = ({
    children,
    color,
    className,
    buttonSize,
    withIcon = false,
    iconOnly = false,
    ...props
}) => {
    const baseClassName = "filled-button"

    const getClassName = () => {
        let className = baseClassName

        if (withIcon) className += ` ${baseClassName}--icon`
        if (iconOnly) className += ` ${baseClassName}--icon-only`
        if (buttonSize) className += ` ${baseClassName}--${buttonSize}`

        return className += ` ${baseClassName}--${color}`
    }

    return (
        <button
            {...props}
            className={`${getClassName()} ${className}`}
        >
            {children}
        </button>
    )
}

export default FilledButton