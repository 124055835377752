import { ChangeEvent, FC, useEffect, useState } from "react";

// Components
import TextBox from "../../../../components/Inputs/TextBox/TextBox";
import Tag from "../../../../components/Tag/Tag";

// Queries
import { useGetAllTagsQuery } from "../../../../../redux/slices/tag/TagApiSlice";
import FilledButton from "../../../../components/Buttons/FilledButton";
import TextButton from "../../../../components/Buttons/TextButton";
import Avatar from "./avatar/Avatar";
import { useGetExpertProfileQuery, useUpdateExpertProfileMutation } from "../../../../../redux/slices/experts/profile/ExpertProfileApiSlice";
import { useUserId } from "../../../../../redux/slices/user/UserSlice";

// TODO: Success-toast
const ProfileInfo: FC = () => {
    const {
        data: tags,
    } = useGetAllTagsQuery()

    const userId = useUserId()

    const {
        data: expert,
    } = useGetExpertProfileQuery(userId)

    const [updateProfile] = useUpdateExpertProfileMutation()

    const [selectedTags, setSelectedTags] = useState<string[]>([])
    const [bio, setBio] = useState<string>("")
    const [changed, setChanged] = useState<boolean>(false)

    const onTagClick = (tag: string) => {
        setChanged(true)

        setSelectedTags(prev => (
            prev?.some(item => item === tag)
                ? [...prev.filter(i => i !== tag)]
                : [tag, ...prev]
        ))
    }

    const onBioChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setBio(e.target.value)
        setChanged(true)
    }

    const onSaveClick = () => {
        updateProfile({
            expertId: userId,
            data: {
                bio,
                tags: selectedTags
            }
        })
            .unwrap()
            .then(() => setChanged(false))
    }

    const onCancelClick = () => {
        setSelectedTags(expert?.tags ?? [])
        setBio(expert?.bio ?? "")
        setChanged(false)
    }

    useEffect(() => {
        setSelectedTags(expert?.tags ?? [])
        setBio(expert?.bio ?? "")
    }, [expert])

    return (
        <div className="profile-info">
            <div className="profile-info__avatar-container">
                <Avatar />
            </div>

            <div className="profile-info__about">
                <h3>Over mij</h3>

                <TextBox
                    className="profile-info__about-input"
                    placeholder="Over..."
                    value={bio}
                    onChange={onBioChange}
                />
            </div>

            <div className="profile-info__tags">
                <h3>Tags</h3>

                <div className="profile-info__tags-container">
                    {
                        tags?.filter(item => selectedTags.includes(item.name)).map(item => (
                            <div
                                key={item.name}
                                className="profile-info__tag"
                            >
                                <Tag
                                    tag={item.name}
                                    selected={selectedTags.includes(item.name)}
                                    onClick={onTagClick}
                                />
                            </div>
                        ))
                    }
                    {
                        tags?.filter(item => !selectedTags.includes(item.name)).map(item => (
                            <div
                                className="profile-info__tag"
                                key={item.name}
                            >
                                <Tag
                                    tag={item.name}
                                    selected={selectedTags.includes(item.name)}
                                    onClick={onTagClick}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
            {
                changed &&
                <div className="profile-info__buttons">
                    <FilledButton
                        color="flame"
                        onClick={onSaveClick}
                    >
                        Opslaan
                    </FilledButton>

                    <TextButton
                        color="grey"
                        onClick={onCancelClick}
                    >
                        Annuleren
                    </TextButton>
                </div>
            }
        </div>
    )
}

export default ProfileInfo