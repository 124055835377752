import { configureStore, isPlain } from "@reduxjs/toolkit";
import rootReducer from "./slices/rootReducer";
import { User } from "oidc-client-ts";
import { api } from "./slices/api/ApiSlice";
import { identityApi } from "./slices/identity/IdentityApiSlice";

export const store = configureStore({
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                isSerializable: (value: any) => {
                    if (value instanceof User) {
                        return true
                    } else {
                        return isPlain(value)
                    }
                }
            }
        }).concat([
            api.middleware,
            identityApi.middleware
        ]),
    reducer: rootReducer
})
