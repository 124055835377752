import React, { FC, PropsWithChildren } from 'react'

interface IProps extends PropsWithChildren {

}

const DatePickerContainer: FC<IProps> = ({ children }) => {
    return (
        <div className='date-picker__container'>
            {children}
        </div>
    )
}

export default DatePickerContainer