import { FC } from "react";
import Header from "../../shared/header/Header";
import Table from "../../shared/table/Table";
import { useGetTagsQuery } from "../../../../redux/slices/tag/TagApiSlice";
import { useSearchParams } from "react-router-dom";
import Delete from "./modals/delete/Delete";
import Create from "./modals/create/Create";
import { Tag } from "../../../../redux/slices/tag/Tag";

const TagsPage: FC = () => {
    const [searchParams] = useSearchParams()

    const pageSize = 10;
    const page = parseInt(searchParams.get("page") ?? "0")

    const {
        data: tags,
        isLoading,
        isFetching
    } = useGetTagsQuery({
        page,
        pageSize: pageSize
    })

    return (
        <div className="tags-page page">
            <Header>
                <h1>Tags</h1>

                <Create />
            </Header>

            <div className="tags-page__content">
                <div className="tags-page__table-container">
                    <Table<Tag>
                        className="tags-page__table"
                        columns={[
                            {
                                name: "Name",
                                className: "tags-page__name-column",
                                render: (item) => item.name
                            },
                            {
                                name: "",
                                className: "tags-page__action-column",
                                render: (item) => <Delete item={item.name} />
                            }
                        ]}
                        page={page}
                        pageSize={pageSize}
                        totalItems={tags?.totalItems ?? 0}
                        items={tags}
                        isFetching={isFetching}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </div>
    )
}

export default TagsPage