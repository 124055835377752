import { FC, PropsWithChildren, useEffect } from "react";
import Portal from "../portal/Portal";
import TextButton from "../../../components/Buttons/TextButton";
import MaterialIcon from "../../../components/Icons/MaterialIcon";
import Loader from "../../../components/Loader/Loader";

interface IProps extends PropsWithChildren {
    show: boolean,
    onClose: () => void,
    className?: string,
    isLoading?: boolean
    isSuccess?: boolean
    isError?: boolean
}

const Modal: FC<IProps> = ({
    children,
    show,
    onClose,
    className,
    isLoading,
    isSuccess,
    isError,
}) => {
    useEffect(() => {
        if (isSuccess) onClose()
    }, [isSuccess, onClose])

    return show ? (
        <Portal>
            <div className={`modal__container ${isError && "modal__container--error"}`}>
                <div className={`modal ${className}`}>
                    {
                        isLoading &&
                        <div className="modal__loader-container">
                            <Loader />
                        </div>
                    }

                    <TextButton
                        color="grey"
                        iconOnly
                        withIcon
                        className="modal__close-button"
                        onClick={onClose}
                    >
                        <MaterialIcon>close</MaterialIcon>
                    </TextButton>
                    {children}
                </div>
            </div>
        </Portal>
    ) : <></>
}

export default Modal