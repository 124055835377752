import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import Table from "../../shared/table/Table";

import { Expert } from "../../../../redux/slices/experts/users/Expert";
import { useGetExpertsQuery } from "../../../../redux/slices/experts/users/ExpertUsersApiSlice";
import Header from "../../shared/header/Header";
import SearchInput from "../../../components/Inputs/SearchInput/SearchInput";
import Invite from "./modals/invite/Invite";
import Tag from "../../../components/Tag/Tag";
import Delete from "./modals/delete/Delete";

const ExpertsPage: FC = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const pageSize = 10
    const page = parseInt(searchParams.get("page") ?? "0")
    const searchTerm = searchParams.get("search")

    const {
        data: experts,
        isLoading,
        isFetching,
        refetch
    } = useGetExpertsQuery({ page, pageSize: pageSize, search: searchTerm ?? undefined });

    const onSearchChange = (term: string) => {
        term !== "" ?
            setSearchParams((prev) => [
                ...Array.from(prev).filter(([key]) => key !== "search"),
                ["search", term]
            ])
            :
            setSearchParams((prev) => [
                ...Array.from(prev).filter(([key]) => key !== "search"),
            ])
    }

    useEffect(() => {
        if (
            !searchParams.has('page') ||
            !searchParams.has('pageSize')
        ) {
            searchParams.has('search') ?
                setSearchParams([
                    ["page", "0"],
                    ["pageSize", "10"],
                    ["search", searchParams.get("search") ?? ""],
                ])
                :
                setSearchParams([
                    ["page", "0"],
                    ["pageSize", "10"]
                ])
        }
    }, [searchParams, setSearchParams])

    return (
        <div className="page experts-page">
            <Header>
                <h1>Experts</h1>

                <Invite />
            </Header>

            <div className="experts-page__content content">
                <div className="experts-page__table-container">
                    <div className="experts-page__table-actions">
                        <SearchInput
                            initialTerm={searchTerm ?? ""}
                            onChange={onSearchChange}
                            className="experts-page__search"
                        />
                    </div>

                    {
                        experts &&
                        <p className="experts-page__table-numbers">
                            {experts.currentPage * experts.pageSize} -
                            {" "}{(experts.currentPage + 1) * experts.pageSize < experts.totalItems ? (experts.currentPage + 1) * experts.pageSize : experts.totalItems} van
                            {" "}{experts.totalItems} totaal</p>
                    }

                    <Table<Expert>
                        columns={[
                            {
                                name: 'Naam',
                                render: (expert) => `${expert.firstName} ${expert.lastName}`,
                                className: 'experts-page__name-column',
                                headerClassName: 'experts-page__email-header'
                            },
                            {
                                name: 'Email',
                                render: (expert) => expert.email,
                                className: 'experts-page__email-column',
                                headerClassName: 'experts-page__email-header'
                            },
                            {
                                name: 'Tags',
                                render: (expert) => (
                                    <>
                                        <div className="experts-page__tags-fade"></div>
                                        {
                                            expert.tags.map(tag => (
                                                <Tag tag={tag} selected key={`expert-${expert.id}-${tag}`} />
                                            ))
                                        }
                                    </>
                                ),
                                className: 'experts-page__tags-column',
                                headerClassName: 'experts-page__tags-header'
                            },
                            {
                                name: '',
                                render: (expert) => <Delete item={expert} onSuccess={() => refetch()} />,
                                className: 'experts-page__actions-column',
                                headerClassName: 'experts-page__actions-header'
                            },
                        ]}
                        className="experts-page__table"
                        items={experts}
                        page={page}
                        pageSize={pageSize}
                        totalItems={experts?.totalItems ?? 0}
                        isLoading={isLoading}
                        isFetching={isFetching}
                    />

                </div>
            </div>
        </div>
    )
}

export default ExpertsPage