import ReactPaginate from "react-paginate"
import MaterialIcon from "../../../components/Icons/MaterialIcon";
import { Paginated } from "../../../../redux/types/Paginated";
import Loader from "../../../components/Loader/Loader";
import { useSearchParams } from "react-router-dom";

interface Column<TItem> {
    name: string,
    render: (item: TItem) => any,
    mobile?: boolean
    className?: string,
    headerClassName?: string
}

interface IProps<TItem> {
    className: string
    columns: Column<TItem>[]
    items?: Paginated<TItem>
    page: number
    pageSize: number
    totalItems: number
    isLoading: boolean
    isFetching: boolean
    onItemClick?: (item: TItem) => any
    onPageChange?: (selected: number) => void
    setSearchParamsOnChange?: boolean
    renderHeader?: boolean
}

function Table<TItem>({
    className,
    columns,
    items,
    page,
    pageSize,
    totalItems,
    isLoading,
    isFetching,
    onPageChange,
    onItemClick,
    setSearchParamsOnChange = true,
    renderHeader = true
}: IProps<TItem>) {
    const [, setSearchParams] = useSearchParams()

    const setPage = (selected: number) => {
        if (onPageChange) onPageChange(selected)

        if (setSearchParamsOnChange) {
            setSearchParams((prev) => [
                ...Array.from(prev)
                    .filter(
                        ([key]) => key !== "page" && key !== "pageSize"
                    ),
                ["page", `${selected}`],
                ["pageSize", `${pageSize}`]
            ])

        }
    }

    return (
        <div className={`table ${className}`}>
            <div className="table__table-container">
                {
                    renderHeader &&
                    <div
                        className="table__row table__row--header"
                    >
                        {
                            columns.map(column => (
                                <div
                                    className={`
                            table__column
                            table__column--header
                            ${column.mobile === false && "table__column--hide-mobile"}
                            ${column.className}
                            ${column.headerClassName}
                            `}
                                    key={`table-header-${column.name}`}
                                >
                                    <p>{column.name}</p>
                                </div>
                            ))
                        }
                    </div>
                }

                {
                    isLoading &&
                    <div className={`table__loading-indicator`}>
                        <Loader />
                    </div>
                }

                {
                    items?.totalItems === 0 && <p className="table__empty-text">Geen items om weer te geven.</p>
                }

                {
                    items && items.items.length > 0 &&
                    <div className="table__rows">
                        {
                            <div className={`table__fetching-indicator ${isFetching && "table__fetching-indicator--show"}`}>
                                <Loader />
                            </div>
                        }

                        {
                            items.items.map((item, index) => (
                                <div
                                    className="table__row"
                                    onClick={onItemClick ? () => onItemClick(item) : undefined}
                                    key={`table-row-${index}`}
                                >
                                    {
                                        columns.map(column => (
                                            <div
                                                className={`table__column ${column.mobile === false && "table__column--hide-mobile"} ${column.className}`}
                                                key={`table-column-${column.name}`}
                                            >
                                                {column.render(item)}
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                }
            </div>
            {
                Math.ceil(totalItems / pageSize) !== 0 &&
                <ReactPaginate
                    className="table__pagination"
                    pageCount={Math.ceil(totalItems / pageSize)}
                    initialPage={page}
                    previousLabel={<MaterialIcon>chevron_left</MaterialIcon>}
                    nextLabel={<MaterialIcon>chevron_right</MaterialIcon>}
                    onPageChange={(selected: any) => setPage(selected.selected)}
                    renderOnZeroPageCount={null}
                />
            }
        </div>
    );
}

export default Table