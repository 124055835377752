import { FC } from "react"
import Picture from "./picture/Picture"
import UploadContainer from "./upload-container/UploadContainer"
import { useGetExpertProfileQuery } from "../../../../../redux/slices/experts/profile/ExpertProfileApiSlice"
import { useUserId } from "../../../../../redux/slices/user/UserSlice"

const ProfileExtra: FC = () => {
    const userId = useUserId();

    const {
        data: expert
    } = useGetExpertProfileQuery(userId)

    return (
        <div className="profile-extra">
            <h1 className="profile-extra__heading">Foto's</h1>

            <div className="profile-extra__gallery">
                {
                    expert?.images.map((image) => (
                        <Picture
                            key={image.id}
                            expertId={expert.id}
                            image={image}
                        />
                    ))
                }

                {
                    expert && expert?.images.length < 5 && (
                        <UploadContainer />
                    )
                }
            </div>
        </div>
    )
}

export default ProfileExtra