import { FC } from "react"
import Sidebar from "../sidebar/Sidebar"
import { Outlet } from "react-router-dom"

const AppContainer: FC = () => {
    return (
        <div className="app__container">
            <Sidebar />
            <div className="app__content">
                <Outlet />
            </div>
        </div>
    )
}

export default AppContainer