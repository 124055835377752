import { FC } from "react";
import ReactDatePicker from "react-datepicker";
import MaterialIcon from "../Icons/MaterialIcon";
import Portal from "../../pages/shared/portal/Portal";
import DatePickerContainer from "./DatePickerContainer";

interface IProps {
    selected?: Date
    onChange: (date: Date) => void
}

const DatePicker: FC<IProps> = ({ selected, onChange }) => {
    return (
        <div className="date-picker">
            <ReactDatePicker
                selected={selected}
                onChange={onChange}
                previousMonthButtonLabel={<MaterialIcon>chevron_left</MaterialIcon>}
                nextMonthButtonLabel={<MaterialIcon>chevron_right</MaterialIcon>}
                dateFormat="dd/MM/yyyy"
                isClearable
                popperPlacement="top"
                popperContainer={({ children }) => <DatePickerContainer>{children}</DatePickerContainer>}
            />
        </div>
    )
}

export default DatePicker