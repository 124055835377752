import { combineReducers } from "redux";
import { userReducer } from "./user/UserSlice";
import { api } from "./api/ApiSlice";
import { identityApi } from "./identity/IdentityApiSlice";

const rootReducer = combineReducers({
    userSlice: userReducer,
    [api.reducerPath]: api.reducer,
    [identityApi.reducerPath]: identityApi.reducer
})

export default rootReducer