import { DetailedHTMLProps, FC, PropsWithChildren } from "react";

interface IProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, PropsWithChildren {
    color: 'flame' | 'turquoise' | 'grey' | 'rich-black' | 'white'
    withIcon?: boolean
    iconOnly?: boolean
}

const TextButton: FC<IProps> = ({
    children,
    color,
    withIcon = false,
    iconOnly = false,
    ...props
}) => {
    const baseClassName = "text-button"

    const getClassName = () => {
        let className = baseClassName

        if (withIcon) className += ` ${baseClassName}--icon`
        if (iconOnly) className += ` ${baseClassName}--icon-only`

        return className += ` ${baseClassName}--${color}`
    }

    return (
        <button
            {...props}
            className={`${getClassName()} ${props.className}`}
        >
            {children}
        </button>
    )
}

export default TextButton