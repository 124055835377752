import { FC, useState } from "react"
import MaterialIcon from "../../Icons/MaterialIcon"

interface IProps {
    initialTerm: string
    onChange: (term: string) => void
    placeholder?: string
    className?: string
    /**
     * Defaults to 500
     */
    delay?: number
}

const SearchInput: FC<IProps> = ({
    initialTerm,
    onChange,
    placeholder = "Zoeken...",
    className,
    delay = 500
}) => {
    const [term, setTerm] = useState<string>(initialTerm)

    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>()

    const delayOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTerm(e.target.value)

        clearTimeout(searchTimeout)

        setSearchTimeout(
            setTimeout(() => {
                if (term !== "") onChange(e.target.value)
            }, delay)
        )
    }

    return (
        <div
            className={`search-input ${className}`}
        >
            <MaterialIcon>search</MaterialIcon>

            <input
                value={term}
                onChange={delayOnChange}
                placeholder={placeholder}
            />
        </div>
    )
}

export default SearchInput