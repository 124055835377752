import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from '../../types'

export const api = createApi({
    reducerPath: 'api',
    tagTypes: ['Tag', 'Expert', 'Customer', 'Chat', 'TimePeriod'],
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, { getState }) => {
            const user = (getState() as RootState).userSlice.user

            if (user) {
                headers.set('Authorization', `Bearer ${user.access_token}`)
            }

            return headers
        },
        baseUrl: process.env.REACT_APP_API_ENDPOINT
    }),
    endpoints: () => ({}),
})