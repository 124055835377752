import { FC, PropsWithChildren } from "react"

interface IProps extends PropsWithChildren {
    onClick?: () => void
    className?: string
}

const MaterialIconOutlined: FC<IProps> = ({ children, onClick, className }) => {
    return (
        <span className={`material-icons-outlined ${className}`} onClick={onClick}>
            {children}
        </span>
    )
}

export default MaterialIconOutlined