import { SerializedError } from "@reduxjs/toolkit"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { FC, useMemo } from "react"
import { camelize } from "../../../../utils/camelize"

interface IProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    underlined?: boolean,
    error?: FetchBaseQueryError | SerializedError,
    existsError?: string
}

const Input: FC<IProps> = ({ existsError, error, underlined, ...props }) => {
    const errors = useMemo(() => {
        if (error === undefined) {
            return []
        }

        if ('data' in error) {
            const allErrors = error.data as Record<string, string[]>

            const errors = Object.entries(allErrors)
                .filter(([key]) => camelize(key) === props.name)[0]

            if (errors) return errors[1]
        }

        return []
    }, [error, props.name])

    const getErrorDescription = (code: string) => {
        const errorName = props.placeholder

        switch (code) {
            case "Exists": {
                return existsError ?? errorName + " bestaat al."
            }
            case "Required": {
                return errorName + " is vereist."
            }
            case "Invalid": {
                return errorName + " is niet valide."
            }
            case "NotFound": {
                return errorName + " niet gevonden."
            }
            default: {
                return ""
            }
        }
    }

    return (
        <div
            className={`input ${underlined && "input--underlined"} ${props.className}`}
        >
            <input
                {...props}
            />

            {
                errors && errors.length > 0 &&
                <div className="input__errors">
                    {
                        errors.map((error, index) => (
                            <p
                                className="input__error"
                                key={`error-${props.name}-${index}`}
                            >
                                {getErrorDescription(error)}
                            </p>
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default Input