export class DateHelper {
    static getFirstDayOfWeek(date?: Date) {
        date = date ? date : new Date()
        
        return new Date(date.getFullYear(), date.getMonth(), date.getDate() - date.getDay())
    }

    static addDays(date: Date, amount: number) {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() + amount,
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    }

    static addMonths(date: Date, amount: number) {
        return new Date(
            date.getFullYear(),
            date.getMonth() + amount,
            date.getDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
            date.getMilliseconds()
        )
    }

    static addMinutes(date: Date, amount: number) {
        return new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            date.getHours(),
            date.getMinutes() + amount,
            date.getSeconds(),
            date.getMilliseconds()
        )
    }

    static isSameDay(date: Date, date2: Date) {
        return date.getFullYear() === date2.getFullYear() &&
            date.getMonth() === date2.getMonth() &&
            date.getDate() === date2.getDate()
    }
}