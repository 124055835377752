import AuthServerProvider from "./AuthServerProvider"

export default class AccountProvider extends AuthServerProvider {
  constructor(bearerToken?: string) {
    super("api/account", bearerToken)
  }

  /**
   * Tries to reset the password with the given email, password and token.
   */
  resetPassword(email: string, password: string, token: string) {
    return this.post<void>({
      path: "reset-password",
      body: JSON.stringify({ email, token, password }),
    })
  }
}
