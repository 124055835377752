import { FC } from "react";
import Header from "./header/Header";
import Days from "./days/Days";

const Calendar: FC = () => {
    return (
        <div className="calendar-container">
            <div className="calendar">
                <Header />

                <div className="calendar__days-wrapper">
                    <Days />
                </div>
            </div>
        </div>
    )
}

export default Calendar