import { FC, useContext } from "react"
import Header from "../../../../shared/header/Header"
import Appointments from "./appointments/Appointments"
import CalendarContext from "../../context/CalendarContext"
import Input from "../../../../../components/Inputs/Input/Input"
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextBox from "../../../../../components/Inputs/TextBox/TextBox"
import DaySelector from "../time-period/day-selector/DaySelector"
import TimeSelect from "../../../../../components/Inputs/TimeSelect/TimeSelect"
import Checkbox from "../../../../../components/Inputs/Checkbox/Checkbox"
import DatePicker from "../../../../../components/DatePicker/DatePicker"

interface IProps {
}

const TimePeriodDetail: FC<IProps> = () => {

    const { detailModal, setDetailModal, editTimePeriod, timePeriod } = useContext(CalendarContext)

    const handleChange = (field: string, value: any) => {
        editTimePeriod({ [field]: value })
    }

    return (
        <div className={`
            time-period-detail__container
            ${detailModal && "time-period-detail__container--show"}
        `}>
            {
                <div className="time-period-detail detail-page">
                    <Header button="close" onClick={() => setDetailModal(false)}>
                        <Input
                            className="time-period-detail__type-input"
                            placeholder="Type"
                            value={timePeriod?.type}
                            onChange={(event) => handleChange("type", event.currentTarget.value)}
                            underlined
                        />

                        <FilledButton color="flame">Opslaan</FilledButton>
                        {/* {renderError("type", "Type")} */}
                    </Header>

                    <div className="time-period-detail__content">
                        <div className="time-period-detail__info">
                            <div className="time-period-detail__tab-title">
                                <h3>Info</h3>
                            </div>

                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Beschrijving</p>

                                <TextBox
                                    className="time-period-detail__description-input"
                                    placeholder="Beschrijving"
                                    value={timePeriod?.description}
                                    onChange={(event) => handleChange("description", event.currentTarget.value)}
                                />
                            </div>

                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Prijs</p>

                                <div className="time-period-detail__input-content">
                                    <Input
                                        placeholder="Prijs"
                                        type="number"
                                        step="0.01"
                                        value={timePeriod?.price}
                                        onChange={(event) => handleChange("price", event.currentTarget.value)}
                                        underlined
                                    />
                                </div>
                            </div>


                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Max. aantal mensen</p>

                                <Input
                                    type="number"
                                    placeholder="1"
                                    value={timePeriod?.maxPeople.toString()}
                                    onChange={(event) => handleChange("maxPeople", event.currentTarget.value)}
                                />
                            </div>


                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Duur</p>

                                <TimeSelect
                                    value={timePeriod?.duration}
                                    onChange={(value) => handleChange("duration", value)}
                                />
                            </div>

                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Herhalen op</p>
                                <div className="time-period-detail__input-content">
                                    <DaySelector />
                                </div>
                            </div>

                            {
                                timePeriod?.days && timePeriod?.days?.length > 0 &&
                                <div className="time-period-modal__input-container">
                                    <div className="time-period-modal__input-wrapper">
                                        <p className="time-period-modal__label">Eind datum</p>

                                        <DatePicker
                                            selected={timePeriod.endDate ? new Date(timePeriod.endDate) : undefined}
                                            onChange={(date: Date) => editTimePeriod({ endDate: date ? date.toISOString() : undefined })}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="time-period-detail__registrations">
                            <div className="time-period-detail__tab-title">
                                <h3>Inschrijvingen</h3>
                            </div>

                            <div className="time-period-detail__input-container">
                                <p className="time-period-detail__input-label">Alleen voor klanten</p>
                                <Checkbox
                                    checked={timePeriod?.customerOnly}
                                    onChange={(event) => handleChange("customerOnly", event.currentTarget.checked)}
                                />
                            </div>

                            <Appointments />
                        </div>
                    </div>
                </div>
            }
        </div >
    )
}

export default TimePeriodDetail