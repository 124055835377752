import { FC, useState } from "react"

// Components
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextButton from "../../../../../components/Buttons/TextButton"
import MaterialIcon from "../../../../../components/Icons/MaterialIcon"
import Modal from "../../../../shared/modals/Modal"

// Slice
import { useCreateTagMutation } from "../../../../../../redux/slices/tag/TagApiSlice"
import Input from "../../../../../components/Inputs/Input/Input"
import ElevatedButton from "../../../../../components/Buttons/ElevatedButton"

interface IProps {
}

const Create: FC<IProps> = () => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const [createTag, { isLoading, isSuccess, error, isError, reset }] = useCreateTagMutation()

    const [tag, setTag] = useState<string>("")

    const onClose = () => {
        setShowModal(false)
        reset()
        setTag("")
    }

    return (
        <>
            <ElevatedButton
                color="flame"
                size="large"
                withIcon
                className="tags-page__add-button"
                onClick={() => setShowModal(true)}
            >
                <MaterialIcon>add</MaterialIcon> Toevoegen
            </ElevatedButton>

            <Modal
                show={showModal}
                onClose={onClose}
                className="tags-page__create"
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={isError}
            >
                <div>
                    <h1 className="tags-page__create-title">Tag toevoegen</h1>

                    <Input
                        className="tags-page__create-input"
                        value={tag}
                        name="tag"
                        onChange={(e) => setTag(e.target.value)}
                        placeholder="Tag"
                        error={error}
                    />
                </div>

                <div className="tags-page__create-buttons">
                    <FilledButton
                        color="flame"
                        className="tags-page__create-button"
                        onClick={() => createTag(tag)}
                    >
                        Opslaan
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="tags-page__create-button"
                        onClick={onClose}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default Create