import { FC, PropsWithChildren } from "react"
import ReactDOM from "react-dom"

const Portal: FC<PropsWithChildren> = ({ children }) => {
    const portal = document.getElementById("portal")

    return portal
        ? ReactDOM.createPortal(children, portal)
        : null
}

export default Portal