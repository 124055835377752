import { IRouteDef } from "./IRouteDef"

interface IAdminRoutes {
    experts: IRouteDef,
    expert: IRouteDef,
    tags: IRouteDef
}

export const AdminRoutes: Record<keyof IAdminRoutes, IRouteDef> = {
    experts: {
        name: "Experts",
        path: "/experts",
        iconName: "group",
        iconOutlined: false,
        sidebar: true
    },
    expert: {
        name: "Expert",
        path: "/experts/:id",
        iconName: "group",
        iconOutlined: false,
        sidebar: false
    },
    tags: {
        name: "Tags",
        path: "/tags",
        iconName: "tag",
        iconOutlined: false,
        sidebar: true
    }
}