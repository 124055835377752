import { FC, useContext } from "react";
import { TimePeriod } from "../../../../../../redux/slices/experts/time-period/TimePeriod";
import MaterialIcon from "../../../../../components/Icons/MaterialIcon";
import MaterialIconOutlined from "../../../../../components/Icons/MaterialIconOutlined";
import CalendarContext from "../../context/CalendarContext";

interface IProps {
    timePeriod: TimePeriod,
    date?: Date,
    editing?: boolean
}

const TimePeriodBlock: FC<IProps> = ({
    timePeriod,
    date,
    editing = false
}) => {
    const { setTimePeriod, setOccurrenceDate } = useContext(CalendarContext)

    const hours = () => {
        const hours = new Date(timePeriod.startDate).getUTCHours() - (
            date === undefined
                ? new Date(timePeriod.startDate).getTimezoneOffset() / 60
                : date.getTimezoneOffset() / 60
        )

        if (hours >= 24) {
            return hours % 24
        } else if (hours < 0) {
            return 23 - hours
        } else {
            return hours
        }
    }

    const minutes = new Date(timePeriod.startDate).getMinutes()

    const handleOnClick = () => {
        if (timePeriod.id && date !== undefined) {
            setTimePeriod(timePeriod)
            setOccurrenceDate(date.getDay())
        }
    }

    return (
        <div
            className={`time-period-block__container ${editing && "time-period-block__container--editing"}`}
            style={{
                height: timePeriod.duration / 60 * 100
            }}
            onClick={handleOnClick}
        >
            <div
                className={`
                    time-period-block
                    ${timePeriod.registrationNumber && timePeriod.registrationNumber > 0 && "time-period-block--enrolled"}
                    ${timePeriod.registrationNumber && timePeriod.registrationNumber === timePeriod.maxPeople && "time-period-block--full"}
                    ${editing && "time-period-block--editing"}`
                }
            >
                <p className="time-period-block__time">{hours() < 10 && "0"}{hours()}:{minutes < 10 && "0"}{minutes}</p>
                <p className="time-period-block__type">{timePeriod.type}</p>
                {timePeriod.customerOnly && <MaterialIconOutlined className="time-period-block__icon">lock</MaterialIconOutlined>}
                {timePeriod.days && timePeriod.days.length > 0 && <MaterialIcon className="time-period-block__icon">event_repeat</MaterialIcon>}
            </div>
        </div>
    )
}

export default TimePeriodBlock