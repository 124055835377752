import { FC, useContext, useEffect, useRef } from "react";
import { DateHelper } from "../../../../../../utils/DateHelper";
import Day from "../day/Day";
import DayHeader from "../day-header/DayHeader";
import CalendarContext from "../../context/CalendarContext";

const Days: FC = () => {
    const headerRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)

    const { date } = useContext(CalendarContext)

    const firstDay = DateHelper.getFirstDayOfWeek(date)

    const getHeaderTop = () => headerRef.current
        ? headerRef.current.getBoundingClientRect().top
        : 0

    const now = new Date()
    const nowMinutes = now.getHours() * 60 + now.getMinutes()

    const scrollY = containerRef.current?.scrollHeight && containerRef.current?.clientHeight
        ? containerRef.current.scrollHeight / (24 * 60) * nowMinutes - containerRef.current.clientHeight / 2
        : 0

    useEffect(() => {
        containerRef.current?.scrollTo(0, scrollY)
    }, [scrollY])

    return (
        <div className="days__container" ref={containerRef}>
            <div
                className="days__header"
                ref={headerRef}
            >
                {
                    Array.from(Array(7)).map((_, index) => (
                        <DayHeader
                            key={`day-header-${index}`}
                            date={DateHelper.addDays(firstDay, index)}
                        />
                    ))
                }
            </div>

            <div className="days">
                {
                    Array.from(Array(7)).map((_, index) => (
                        <Day
                            key={`day-${index}`}
                            date={DateHelper.addDays(firstDay, index)}
                            headerTopY={getHeaderTop()}
                            containerHeight={containerRef.current?.getBoundingClientRect().height}
                        />
                    ))
                }
            </div>
        </div>
    )
}

export default Days