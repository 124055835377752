import { FC, useState } from "react";
import FilledButton from "../../../../../../components/Buttons/FilledButton";
import MaterialIcon from "../../../../../../components/Icons/MaterialIcon";
import Modal from "../../../../../shared/modals/Modal";
import { ExpertImage } from "../../../../../../../redux/slices/experts/users/ExpertImage";
import TextButton from "../../../../../../components/Buttons/TextButton";
import { useDeleteGalleryImageMutation } from "../../../../../../../redux/slices/experts/profile/ExpertProfileApiSlice";
import { useUserId } from "../../../../../../../redux/slices/user/UserSlice";

interface IProps {
    image: ExpertImage
}

const Delete: FC<IProps> = ({ image }) => {
    const [deleteImage, { isLoading, isSuccess }] = useDeleteGalleryImageMutation()

    const userId = useUserId();

    const [showModal, setShowModal] = useState<boolean>(false)

    return (
        <>
            <FilledButton
                color="flame"
                iconOnly
                withIcon
                className="profile-extra__picture-delete"
                onClick={() => setShowModal(true)}
            >
                <MaterialIcon>delete</MaterialIcon>
            </FilledButton>

            <Modal
                show={showModal}
                onClose={() => setShowModal(false)}
                isLoading={isLoading}
                isSuccess={isSuccess}
            >
                <div>
                    <h1 className="delete__title">Verwijderen</h1>

                    <p className="delete__text">Weet je zeker dat je deze foto wilt verwijderen?</p>
                </div>

                <div className="delete__buttons">
                    <FilledButton
                        color="flame"
                        className="delete-button"
                        onClick={() => deleteImage({
                            expertId: userId,
                            data: image.id
                        })}
                    >
                        Bevestigen
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="delete-button"
                        onClick={() => setShowModal(false)}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default Delete