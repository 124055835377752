import { FC, useEffect, useState } from "react"

// Components
import FilledButton from "../../../../../components/Buttons/FilledButton"
import TextButton from "../../../../../components/Buttons/TextButton"
import MaterialIcon from "../../../../../components/Icons/MaterialIcon"
import Modal from "../../../../shared/modals/Modal"

// Slice
import Input from "../../../../../components/Inputs/Input/Input"
import ElevatedButton from "../../../../../components/Buttons/ElevatedButton"
import { useInviteCustomerMutation } from "../../../../../../redux/slices/experts/customer/ExpertCustomerApiSlice"
import { useUserId } from "../../../../../../redux/slices/user/UserSlice"
import { ExceptionHelper } from "../../../../../../redux/helpers/ExceptionHelper"
import { BadRequest } from "../../../../../../redux/types/BadRequest"

interface IProps {
}

const InviteModal: FC<IProps> = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [errorMessage, setErrorMessage] = useState<string>()

    const [
        inviteCustomer,
        {
            isLoading,
            isSuccess,
            error,
            isError,
            reset
        }
    ] = useInviteCustomerMutation()

    const userId = useUserId();

    const [email, setEmail] = useState<string>("")

    const onClose = () => {
        setShowModal(false)
        reset()
        setEmail("")
    }

    const onInviteClick = () => {
        reset()
        inviteCustomer({ expertId: userId, data: email })
    }

    useEffect(() => {
        if (error) {
            if (ExceptionHelper.isBadRequest(error)) {
                const e = error as BadRequest

                console.log(e)

                if (e.data["Email"][0] === "Exists") {
                    setErrorMessage("U heeft deze gebruiker al een uitnodiging gestuurd.")
                } else if (e.data["Email"][0] === "NotFound") {
                    setErrorMessage("Deze gebruiker is onbekend bij ons.")
                }
            } else {
                setErrorMessage("Een onbekend probleem is opgetreden. Onze excuses voor het ongemak.")
            }
        } else {
            setErrorMessage(undefined)
        }
    }, [error])

    return (
        <>
            <ElevatedButton
                color="flame"
                size="large"
                withIcon
                className="customers-page__add-button"
                onClick={() => setShowModal(true)}
            >
                <MaterialIcon>add</MaterialIcon> <p>Uitnodigen</p>
            </ElevatedButton>

            <Modal
                show={showModal}
                onClose={onClose}
                className="customers-page__create"
                isLoading={isLoading}
                isSuccess={isSuccess}
                isError={isError}
            >
                <div>
                    <h1 className="customers-page__invite-title">Klant uitnodigen</h1>

                    <Input
                        className="customers-page__invite-input"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        error={error}
                        existsError="Je hebt deze klant al uitgenodigd."
                    />
                </div>

                <div className="customers-page__invite-buttons">
                    <FilledButton
                        color="flame"
                        className="customers-page__invite-button"
                        onClick={onInviteClick}
                    >
                        Opslaan
                    </FilledButton>

                    <TextButton
                        color="grey"
                        className="customers-page__invite-button"
                        onClick={onClose}
                    >
                        Annuleren
                    </TextButton>
                </div>
            </Modal>
        </>
    )
}

export default InviteModal