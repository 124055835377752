import { DetailedHTMLProps, FC, PropsWithChildren } from "react";

interface IProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>, PropsWithChildren {
    color: 'flame' | 'turquoise'
    size?: 'small' | 'medium' | 'large'
    withIcon?: boolean
    iconOnly?: boolean
}

const ElevatedButton: FC<IProps> = ({
    children,
    color,
    size = 'medium',
    withIcon = false,
    iconOnly = false,
    ...props
}) => {
    const baseClassName = "elevated-button"

    const getClassName = () => {
        let className = baseClassName

        if (withIcon) className += ` ${baseClassName}--icon`
        if (iconOnly) className += ` ${baseClassName}--icon-only`
        className += ` ${baseClassName}--${size}`

        return className += ` ${baseClassName}--${color}`
    }

    return (
        <button
            {...props}
            className={`${getClassName()} ${props.className}`}
        >
            {children}
        </button>
    )
}

export default ElevatedButton