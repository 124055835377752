import { FC, PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { TimePeriodMutation } from "../../../../../redux/slices/experts/time-period/TimePeriodMutation";
import { useGetAllTimePeriodsQuery } from "../../../../../redux/slices/experts/time-period/TimePeriodApiSlice";
import { DateHelper } from "../../../../../utils/DateHelper";
import { useSearchParams } from "react-router-dom";
import { useUserId } from "../../../../../redux/slices/user/UserSlice";
import { TimePeriod } from "../../../../../redux/slices/experts/time-period/TimePeriod";

interface ICalendarContext {
    date?: Date,
    setDate: (date: Date) => void
    originalTimePeriod: TimePeriod | undefined
    timePeriod: TimePeriodMutation | undefined
    timePeriods: TimePeriod[] | undefined
    occurrenceDate: number | undefined
    setOccurrenceDate: React.Dispatch<React.SetStateAction<number | undefined>>
    editTimePeriod: (timePeriod?: Partial<TimePeriodMutation>) => void
    setTimePeriod: (timePeriod: TimePeriod) => void
    setNewTimePeriod: (date: Date) => void
    emptyTimePeriod: () => void
    detailModal: boolean
    setDetailModal: React.Dispatch<React.SetStateAction<boolean>>
    isLoading: boolean
}

const CalendarContext = createContext({} as ICalendarContext)

export default CalendarContext

export const CalendarContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const userId = useUserId();

    const setDate = useCallback((date: Date) => {
        setSearchParams({ date: date.toISOString() })
    }, [setSearchParams])

    const date = useMemo(() => {
        let param = searchParams.get("date")

        if (param) {
            return new Date(param)
        }
    }, [searchParams])

    useEffect(() => {
        if (!date) setDate(new Date())
    }, [date, setDate])

    const [originalTimePeriod, _setOriginalTimePeriod] = useState<TimePeriod>()
    const [timePeriod, _setTimePeriod] = useState<TimePeriodMutation>()
    const [occurrenceDate, setOccurrenceDate] = useState<number>()
    const [detailModal, setDetailModal] = useState<boolean>(false)
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const {
        data: timePeriods,
        isLoading,
        isFetching
    } = useGetAllTimePeriodsQuery({
        expertId: userId,
        startDate: DateHelper.getFirstDayOfWeek(date).toISOString(),
        endDate: DateHelper.addDays(
            DateHelper.getFirstDayOfWeek(date),
            6
        ).toISOString()
    })

    const setNewTimePeriod = (date: Date) => {
        _setTimePeriod({
            startDate: date.toISOString(),
            duration: 60,
            type: "",
            description: "",
            timezone: timeZone,
            customerOnly: true,
            price: "50.00",
            maxPeople: 1
        })
    }

    const editTimePeriod = (partial?: Partial<TimePeriodMutation>) => {
        if (!partial) return _setTimePeriod(undefined)

        let end;
        let start;
        let duration;


        if (timePeriod && partial.endDate) {
            end = new Date(partial.endDate)
            start = new Date(timePeriod.startDate)
            duration = timePeriod.duration
        }

        if (timePeriod?.endDate && partial.startDate) {
            end = new Date(timePeriod.endDate)
            start = new Date(partial.startDate)
            duration = timePeriod.duration
        }

        if (timePeriod?.endDate && partial.duration) {
            end = new Date(timePeriod.endDate)
            start = new Date(timePeriod.startDate)
            duration = partial.duration
        }

        if (timePeriod && end && start && duration) {
            let daysDiff = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24))
            partial.endDate = DateHelper.addMinutes(
                DateHelper.addDays(start, daysDiff),
                duration
            ).toISOString()
        }

        _setTimePeriod(prev =>
            !prev
                ? undefined
                : ({
                    ...prev,
                    ...partial
                })
        )
    }

    const setTimePeriod = (timePeriod: TimePeriod) => {
        _setOriginalTimePeriod(timePeriod)
        _setTimePeriod(timePeriod)
    }

    const emptyTimePeriod = () => editTimePeriod(undefined)

    useEffect(() => {
        if (!timePeriod?.id) setOccurrenceDate(undefined)
    }, [timePeriod])

    return (
        <CalendarContext.Provider
            value={{
                date,
                setDate,
                originalTimePeriod,
                timePeriod,
                occurrenceDate,
                setOccurrenceDate,
                editTimePeriod,
                setTimePeriod,
                setNewTimePeriod,
                emptyTimePeriod,
                timePeriods,
                detailModal,
                setDetailModal,
                isLoading: isLoading || isFetching
            }}
        >
            {children}
        </CalendarContext.Provider>
    )
}