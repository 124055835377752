import { FC, FormEvent, useState } from "react";
import { useGetChatQuery, useSendMessageMutation } from "../../../../../redux/slices/chats/ChatsApiSlice";
import { useUser } from "../../../../../redux/slices/user/UserSlice";
import { Logo } from "../../../../components/Logo/Logo";
import Input from "../../../../components/Inputs/Input/Input";
import MaterialIcon from "../../../../components/Icons/MaterialIcon";
import FilledButton from "../../../../components/Buttons/FilledButton";

interface IProps {
    chatId: string,
    onClose: () => void
}

const ChatView: FC<IProps> = ({ chatId, onClose }) => {
    const [message, setMessage] = useState<string>("")

    const {
        data: chat
    } = useGetChatQuery(chatId, {
        pollingInterval: 2000
    })

    const [
        sendMessage
    ] = useSendMessageMutation()

    const user = useUser()

    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        sendMessage({ id: chatId, message })
            .unwrap()
            .then(_ => setMessage(""))
    }

    if (!chat) return <>a</>

    const chattingWith = chat?.participants.filter(p => p.id !== user?.profile.sub)[0]

    return (
        <>
            <div className="chat-view__header">
                <button className="chat-view__close" onClick={onClose}>
                    <MaterialIcon>close</MaterialIcon>
                </button>

                <div className="chat-view__avatar-container">
                    <Logo.Small
                        className="chat-view__avatar"
                    />
                </div>

                <div className="chat-view__name">
                    <p>{chattingWith.firstName} {chattingWith.lastName}</p>
                </div>
            </div>

            <div className="chat-view__messages-container">
                {
                    chat.messages.map((message, index) => {
                        const own = message.userId === user?.profile.sub

                        const date = new Date(message.sent)

                        const hoursAsString = date.getHours() >= 10 ? date.getHours() : "0" + date.getHours()
                        const minutesAsString = date.getMinutes() >= 10 ? date.getMinutes() : "0" + date.getMinutes()

                        return (
                            <div
                                className={`chat-view__message ${own && "chat-view__message--own"}`}
                                key={index}
                            >
                                <p className={`chat-view__message-text ${own && "chat-view__message-text--own"}`}>{message.message}</p>
                                <p className={`chat-view__message-time ${own && "chat-view__message-time--own"}`}>{hoursAsString}:{minutesAsString}</p>
                            </div>
                        );
                    })
                }
            </div>

            <div className="chat-view__text-input">
                <form onSubmit={onSubmit}>
                    <Input
                        placeholder="Typ een bericht..."
                        onChange={(e) => setMessage(e.target.value)}
                        value={message}
                    />

                    <FilledButton
                        color="flame"
                        withIcon
                        iconOnly
                        type="submit"
                    >
                        <MaterialIcon>send</MaterialIcon>
                    </FilledButton>
                </form>

            </div>
        </>
    )
}

export default ChatView