import React from "react"
import { ReactComponent as FitBuddyWide } from "../../assets/images/fit_buddy_wide.svg"
import { ReactComponent as FitBuddySmall } from "../../assets/images/fitbuddy_logo.svg"
import { FC } from "react"

interface IProps {
    className?: string
}

export class Logo {
    static Wide: FC<IProps> = (props) => <FitBuddyWide {...props} />
    static Small: FC<IProps> = (props) => <FitBuddySmall {...props} />
}