import { FC, useContext } from "react";
import TextButton from "../../../../../components/Buttons/TextButton";
import MaterialIcon from "../../../../../components/Icons/MaterialIcon";
import { DateHelper } from "../../../../../../utils/DateHelper";
import CalendarContext from "../../context/CalendarContext";
import LoaderOverlay from "../../../../../components/LoaderOverlay/LoaderOverlay";

const months = [
    "Januari",
    "Februari",
    "Maart",
    "April",
    "Mei",
    "Juni",
    "Juli",
    "Augustus",
    "September",
    "Oktober",
    "November",
    "December",
]

const Header: FC = () => {
    const { date, setDate } = useContext(CalendarContext)

    if (!date) return <LoaderOverlay show />

    const addDays = () => {
        // Switch on month/day
        setDate(DateHelper.addDays(date, 7))
    }

    const substractDays = () => {
        // Switch on month/day
        setDate(DateHelper.addDays(date, -7))
    }

    return (
        <div className="calendar__header">
            <h1 className="calendar__header-month">{months[date.getMonth()]} {date.getFullYear()}</h1>

            <div className="calendar__header-buttons">
                <TextButton
                    color="grey"
                    iconOnly
                    withIcon
                    onClick={substractDays}
                >
                    <MaterialIcon>chevron_left</MaterialIcon>
                </TextButton>

                <TextButton
                    color="grey"
                    iconOnly
                    withIcon
                    onClick={addDays}
                >
                    <MaterialIcon>chevron_right</MaterialIcon>
                </TextButton>
            </div>
        </div>
    )
}

export default Header