import { FC, PropsWithChildren } from "react"

interface IProps extends PropsWithChildren {
    onClick?: React.MouseEventHandler<HTMLDivElement> | undefined
    className?: string
}

/**
 * @type {React.FC<IProps>}
 * 
 * @param {React.ReactNode} props.children
 * @param {React.MouseEventHandler<HTMLDivElement> | undefined} props.onClick
 * @param {string} props.className
 */
const MaterialIcon: FC<IProps> = ({
    children,
    onClick,
    className
}) => {
    return (
        <span className={`material-icons ${className}`} onClick={onClick}>
            {children}
        </span>
    )
}

export default MaterialIcon