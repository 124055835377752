import { FC } from "react"
import Loader from "../Loader/Loader"

interface IProps {
    show: boolean
}

const LoaderOverlay: FC<IProps> = ({ show }) => {
    return show
        ? (
            <div className="loader-overlay">
                <Loader />
            </div>
        )
        : <></>
}

export default LoaderOverlay