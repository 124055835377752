import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from "react";
import { Logo } from "../../../../../components/Logo/Logo";
import ImageSkeleton from "../../../../../components/ImageSkeleton/ImageSkeleton";
import Cropper, { Area } from "react-easy-crop"
import ImageHelper from "../../../../../../utils/ImageHelper";
import Portal from "../../../../shared/portal/Portal";
import FilledButton from "../../../../../components/Buttons/FilledButton";
import TextButton from "../../../../../components/Buttons/TextButton";
import LoaderOverlay from "../../../../../components/LoaderOverlay/LoaderOverlay";
import { useGetExpertProfileQuery, useUploadAvatarMutation } from "../../../../../../redux/slices/experts/profile/ExpertProfileApiSlice";
import { useUserId } from "../../../../../../redux/slices/user/UserSlice";

// TODO: Toast error
const Avatar: FC = () => {
    const userId = useUserId()

    const {
        data: expert,
    } = useGetExpertProfileQuery(userId)

    const [uploadAvatar, {
        isLoading
    }] = useUploadAvatarMutation()

    const [currentImage, setCurrentImage] = useState<string>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [croppedArea, setCroppedArea] = useState<Area>()
    const [zoom, setZoom] = useState(1)

    const formRef = useRef<HTMLFormElement>(null)
    const imageInputRef = useRef<HTMLInputElement>(null)

    const { imageUrl, key } = useMemo(() => ({
        imageUrl: expert?.avatarUrl,
        key: new Date().toISOString()
    }), [expert])

    const onClick = () => {
        if (imageInputRef.current) {
            imageInputRef.current.click()
        }
    }

    const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files !== null && formRef.current) {
            const image = e.target.files[0]

            const reader = new FileReader()
            reader.readAsDataURL(image)
            reader.addEventListener("load", () => {
                if (typeof reader.result === "string") {
                    setCurrentImage(reader.result)
                }
            }, { once: true })
        }
    }

    const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
        setCroppedArea(croppedAreaPixels)
    }

    const handleSubmit = async () => {
        if (currentImage == null || croppedArea == null) return

        const imageCanvas = await ImageHelper.getCroppedImageCanvas(currentImage, croppedArea)

        imageCanvas.toBlob(blob => {
            if (blob === null) throw new Error()

            const data = new FormData()

            data.append("data", blob, "profile-image.jpg")

            uploadAvatar({
                expertId: userId,
                data
            })
                .unwrap()
                .then(() => setCurrentImage(undefined))
        }, "image/jpeg")
    }

    useEffect(() => {
        if (currentImage == null && formRef.current) {
            formRef.current.reset()
        }
    }, [currentImage])

    return (
        <div className="profile-info__avatar">
            <LoaderOverlay show={isLoading} />
            {
                currentImage &&
                <Portal>
                    <div className="profile-info__avatar-modal">
                        <Cropper
                            crop={crop}
                            zoom={zoom}
                            image={currentImage}
                            aspect={1 / 1}
                            onCropChange={setCrop}
                            onCropComplete={onCropComplete}
                            onZoomChange={setZoom}
                            classes={{
                                containerClassName: "profile-info__avatar-modal-container"
                            }}
                        />
                        <div className="profile-info__avatar-buttons-container">
                            <div className="profile-info__avatar-buttons">
                                <FilledButton
                                    color="flame"
                                    onClick={handleSubmit}
                                >
                                    Opslaan
                                </FilledButton>

                                <TextButton
                                    color="white"
                                    onClick={() => setCurrentImage(undefined)}
                                >
                                    Annuleren
                                </TextButton>
                            </div>
                        </div>
                    </div>
                </Portal>
            }

            {
                expert &&
                <ImageSkeleton
                    src={`${imageUrl}?${key}`}
                />
            }

            <div className="profile-info__avatar-upload" onClick={onClick}>
                <p>Upload</p>

                <form method="post" encType="multipart/form-data" ref={formRef}>
                    <div>
                        <input
                            type="file"
                            onChange={handleImageChange}
                            hidden
                            ref={imageInputRef}
                        />
                    </div>
                </form>
            </div>

            <Logo.Small />
        </div>
    )
}

export default Avatar