import { FC, PropsWithChildren, createContext, useEffect, useState } from "react"

interface ISidebarContext {
    sidebarOpen: boolean
    toggleSidebar: () => void
}

export const SidebarContext = createContext({} as ISidebarContext)

export const SidebarContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [sidebarOpen, setSidebarOpen] = useState<boolean>(false)

    const toggleSidebar = () => {
        setSidebarOpen(prev => !prev)
        localStorage.setItem("sidebarOpen", !sidebarOpen ? "open" : "closed")
    }

    useEffect(() => {
        const sidebarLocal = localStorage.getItem("sidebarOpen")

        if (sidebarLocal === null) {
            localStorage.setItem("sidebarOpen", "open")
        } else {
            setSidebarOpen(sidebarLocal === "open")
        }
    }, [])

    return (
        <SidebarContext.Provider
            value={{
                sidebarOpen,
                toggleSidebar
            }}
        >
            {children}
        </SidebarContext.Provider>
    )
}